var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c(
            "q-layout-header",
            { staticClass: "opacity-0", attrs: { id: "productsEditHeader" } },
            [
              _c(
                "q-toolbar",
                {
                  staticStyle: { "min-height": "82px" },
                  attrs: { inverted: "" }
                },
                [
                  _c("q-toolbar-title", [
                    _c(
                      "div",
                      {
                        staticClass: "text-center cursor-pointer",
                        staticStyle: { "padding-top": "13px" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "title-value text-family-brand text-weight-regular text-gold block"
                          },
                          [
                            _c("sup", { staticClass: "block" }, [_vm._v(" ")]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-subinfo text-weight-bold capitalize text-fixedwidth-brand"
                              },
                              [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      mode: "out-in",
                                      appear: "",
                                      "enter-active-class":
                                        "animated slideInUp",
                                      "leave-active-class":
                                        "animated fadeOutDown"
                                    }
                                  },
                                  [
                                    /*!amIOnline &&*/ !_vm.clients.length ||
                                    _vm.channelFetchActiveClientsRequestsCount ||
                                    _vm.channelFetchActiveClientsRequestActive
                                      ? _c(
                                          "span",
                                          {
                                            key: "chip-work",
                                            staticClass:
                                              "relative-position uppercase chip-live chip-live-blue",
                                            staticStyle: {
                                              top: "-11px",
                                              width: "auto !important"
                                            }
                                          },
                                          [
                                            _c("q-icon", {
                                              attrs: {
                                                color: "white",
                                                name: "ion-code-working"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm.amIOnline
                                      ? _c(
                                          "span",
                                          {
                                            key: "chip-live",
                                            staticClass:
                                              "relative-position uppercase chip-live",
                                            staticStyle: {
                                              top: "-11px",
                                              width: "auto !important"
                                            }
                                          },
                                          [_vm._v("LIVE")]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            key: "chip-offl",
                                            staticClass:
                                              "relative-position uppercase chip-offline",
                                            staticStyle: {
                                              top: "-11px",
                                              width: "auto !important"
                                            }
                                          },
                                          [_vm._v("AWAY")]
                                        )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "text-weight-medium block",
                        staticStyle: { "margin-top": "10px" },
                        attrs: { slot: "subtitle" },
                        slot: "subtitle"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "block text-system-brand text-weight-bold title-subject text-black no-text-transform overflow-hidden"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.product.data.business.name) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("span", {
                          staticClass:
                            "line-height-sm block text-system-brand text-weight-semibold text-subinfo uppercase overflow-hidden",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.product.data.business.address.html
                            )
                          }
                        })
                      ]
                    )
                  ]),
                  _c(
                    "q-btn",
                    {
                      staticClass: "absolute z-top",
                      attrs: { round: "", size: "lg", "text-color": "primary" },
                      on: { click: _vm.exitRequest }
                    },
                    [_c("q-icon", { attrs: { name: "ion-arrow-up" } })],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      staticClass: "absolute z-top",
                      attrs: { round: "", size: "lg", "text-color": "primary" },
                      on: { click: _vm.dialogSettingsShowOpen }
                    },
                    [_c("q-icon", { attrs: { name: "ion-more" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "productsEditContent",
              staticClass: "layout-padding no-pointer-events",
              staticStyle: { "padding-top": "0" },
              attrs: { id: "productsEditContent" }
            },
            [
              _c("q-scroll-observable", { on: { scroll: _vm.scrolled } }),
              _c(
                "div",
                { staticClass: "product" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "hidden row no-wrap items-center margin-auto-lr relative-position",
                      staticStyle: { "max-width": "400px", "z-index": "1" }
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "protect",
                                    "text-color": "white",
                                    rounded: ""
                                  },
                                  on: { click: _vm.channelRequestsClear }
                                },
                                [
                                  _vm._v(
                                    "\n              Reset   Queue\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { "margin-top": "50px" } }),
                  _c(
                    "q-tabs",
                    {
                      staticStyle: {
                        "margin-top": "-60px",
                        "margin-bottom": "40px"
                      },
                      attrs: { color: "gray", animated: "", inverted: "" }
                    },
                    [
                      _c(
                        "q-tab",
                        {
                          staticStyle: { margin: "0 10px" },
                          attrs: {
                            slot: "title",
                            default: "",
                            name: "tab-today",
                            icon: "ion-pulse"
                          },
                          slot: "title"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "on-top-sm font-size-120p text-system-brand text-weight-semibold"
                            },
                            [_vm._v("Today")]
                          )
                        ]
                      ),
                      _c(
                        "q-tab",
                        {
                          staticStyle: { margin: "0 10px" },
                          attrs: {
                            slot: "title",
                            name: "tab-services",
                            icon: "ion-ios-paper"
                          },
                          slot: "title"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "on-top-sm font-size-120p text-system-brand text-weight-semibold"
                            },
                            [_vm._v("Services")]
                          )
                        ]
                      ),
                      _c(
                        "q-tab",
                        {
                          staticStyle: { margin: "0 10px" },
                          attrs: {
                            slot: "title",
                            disable: _vm.isProduction,
                            name: "tab-team",
                            icon: "ion-people"
                          },
                          slot: "title"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "on-top-sm font-size-120p text-system-brand text-weight-semibold"
                            },
                            [_vm._v("Team")]
                          )
                        ]
                      ),
                      _c(
                        "q-tab-pane",
                        { attrs: { name: "tab-today", "keep-alive": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "on-top-lg module-grid-layout-ignore"
                            },
                            [
                              _c(
                                "masonry",
                                {
                                  attrs: {
                                    cols: { default: 2, 680: 1 },
                                    gutter: { default: 30, 680: 15 }
                                  }
                                },
                                [
                                  _vm.settings_dev_presense
                                    ? _c(
                                        "q-list",
                                        {
                                          staticClass:
                                            "text-system-brand text-weight-medium q-list-depth",
                                          staticStyle: {
                                            "max-width": "320px !important",
                                            margin: "30px auto !important"
                                          }
                                        },
                                        [
                                          _c(
                                            "q-list-header",
                                            {
                                              staticClass:
                                                "no-margin-top text-weight-bold text-tertiary module-title-size"
                                            },
                                            [
                                              _c("q-icon", {
                                                staticClass: "on-left-sm",
                                                attrs: {
                                                  color: _vm.amIOnline
                                                    ? "educate"
                                                    : "protect",
                                                  name:
                                                    "ion-information-circle",
                                                  size: "32px"
                                                }
                                              }),
                                              _vm._v(
                                                " Active Clients\n              "
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item",
                                            {
                                              attrs: { link: "", tag: "label" },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.channelFetchActiveClients()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "q-item-side",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("q-icon", {
                                                    attrs: {
                                                      name: "ion-repeat",
                                                      color: _vm.anyDarkmode
                                                        ? ""
                                                        : "blue-grey-10",
                                                      size: "33px"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "q-item-main",
                                                [
                                                  _c(
                                                    "q-item-tile",
                                                    { attrs: { label: "" } },
                                                    [_vm._v("Fetch Clients")]
                                                  ),
                                                  _c(
                                                    "q-item-tile",
                                                    { attrs: { sublabel: "" } },
                                                    [
                                                      _vm._v(
                                                        "Refresh list of users"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("q-item-separator"),
                                          _vm.clients.length
                                            ? _c(
                                                "div",
                                                _vm._l(_vm.clients, function(
                                                  client
                                                ) {
                                                  return _c(
                                                    "q-item",
                                                    {
                                                      key: client.uuid,
                                                      attrs: { item: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "q-item-side",
                                                        {
                                                          staticClass:
                                                            "text-center"
                                                        },
                                                        [
                                                          client.state &&
                                                          client.state.role ===
                                                            "admin"
                                                            ? _c("q-icon", {
                                                                attrs: {
                                                                  name:
                                                                    "ion-medal",
                                                                  color:
                                                                    "educate",
                                                                  size: "33px"
                                                                }
                                                              })
                                                            : client.uuid ===
                                                              _vm.product.data
                                                                .uri
                                                            ? _c("q-icon", {
                                                                attrs: {
                                                                  name:
                                                                    "ion-star",
                                                                  color: "gold",
                                                                  size: "33px"
                                                                }
                                                              })
                                                            : _c("q-icon", {
                                                                attrs: {
                                                                  name:
                                                                    "ion-contact",
                                                                  color: "gray",
                                                                  size: "33px"
                                                                }
                                                              })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "q-item-main",
                                                        [
                                                          _c(
                                                            "q-item-tile",
                                                            {
                                                              attrs: {
                                                                label: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    client.state &&
                                                                      client
                                                                        .state
                                                                        .role ===
                                                                        "admin"
                                                                      ? "Admin"
                                                                      : "User"
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                              client.uuid ===
                                                              _vm.product.data
                                                                .uri
                                                                ? _c(
                                                                    "q-chip",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "YOU"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "q-item-tile",
                                                            {
                                                              attrs: {
                                                                sublabel: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "q-chip",
                                                                {
                                                                  attrs: {
                                                                    dense: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      client.uuid
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              )
                                            : _c("div", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "text-center"
                                                  },
                                                  [_vm._v("No clients")]
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "q-list",
                                    {
                                      staticClass:
                                        "text-system-brand text-weight-medium q-list-depth",
                                      staticStyle: {
                                        "max-width": "320px !important",
                                        margin: "30px auto !important"
                                      },
                                      attrs: { highlight: "", link: "" }
                                    },
                                    [
                                      _vm.today_day
                                        ? [
                                            _vm.product.data.business.hoo[
                                              _vm.today_day
                                            ].is24
                                              ? _c("q-progress", {
                                                  staticStyle: {
                                                    position: "relative",
                                                    top: "-8px",
                                                    left: "0",
                                                    "margin-bottom": "-4px"
                                                  },
                                                  attrs: {
                                                    stripe: "",
                                                    indeterminate: "",
                                                    color: "educate",
                                                    height: "4px"
                                                  }
                                                })
                                              : _vm.product.data.business.hoo[
                                                  _vm.today_day
                                                ].isClosed
                                              ? _c("q-progress", {
                                                  staticStyle: {
                                                    position: "relative",
                                                    top: "-8px",
                                                    left: "0",
                                                    "margin-bottom": "-4px"
                                                  },
                                                  attrs: {
                                                    percentage: 100,
                                                    stripe: "",
                                                    color: "protect",
                                                    height: "4px"
                                                  }
                                                })
                                              : _vm.today_time_elapsed >= 0 &&
                                                _vm.today_time_elapsed < 100
                                              ? _c("q-progress", {
                                                  staticStyle: {
                                                    position: "relative",
                                                    top: "-8px",
                                                    left: "0",
                                                    "margin-bottom": "-4px"
                                                  },
                                                  attrs: {
                                                    percentage:
                                                      _vm.today_time_elapsed /
                                                      1,
                                                    stripe: "",
                                                    buffer:
                                                      100 -
                                                      _vm.today_time_elapsed /
                                                        1,
                                                    color:
                                                      _vm.today_time_elapsed >=
                                                      90
                                                        ? "attention"
                                                        : "educate",
                                                    height: "4px"
                                                  }
                                                })
                                              : _c("q-progress", {
                                                  staticStyle: {
                                                    position: "relative",
                                                    top: "-8px",
                                                    left: "0",
                                                    "margin-bottom": "-4px"
                                                  },
                                                  attrs: {
                                                    percentage: 100,
                                                    stripe: "",
                                                    color: "protect",
                                                    height: "4px"
                                                  }
                                                })
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "q-list-header",
                                        {
                                          staticClass:
                                            "no-margin-top text-weight-bold text-secondary module-title-size"
                                        },
                                        [
                                          _c("q-btn", {
                                            staticClass: "float-right",
                                            staticStyle: {
                                              "margin-top": "-10px",
                                              "margin-right": "-10px"
                                            },
                                            attrs: {
                                              round: "",
                                              size: "18px",
                                              "text-color": "secondary",
                                              icon: "ion-more"
                                            },
                                            on: {
                                              click: _vm.moduleSettingsToday
                                            }
                                          }),
                                          _c("q-icon", {
                                            staticClass: "on-left-sm",
                                            attrs: {
                                              name: "ion-calendar",
                                              color: "secondary",
                                              size: "32px"
                                            }
                                          }),
                                          _vm._v(" Today\n              ")
                                        ],
                                        1
                                      ),
                                      _c("q-item-separator"),
                                      _c(
                                        "q-item",
                                        {
                                          attrs: {
                                            item: "",
                                            link: "",
                                            tag: "label"
                                          }
                                        },
                                        [
                                          _c(
                                            "q-item-side",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "transition",
                                                {
                                                  attrs: {
                                                    mode: "out-in",
                                                    appear: "",
                                                    "enter-active-class":
                                                      "animated400 bounceIn",
                                                    "leave-active-class":
                                                      "animated400 bounceOut"
                                                  }
                                                },
                                                [
                                                  _vm.channel_online
                                                    ? _c("q-icon", {
                                                        key:
                                                          "channel-online-on",
                                                        staticClass: "pulse",
                                                        attrs: {
                                                          name: "ion-radio",
                                                          color: _vm.anyDarkmode
                                                            ? ""
                                                            : "blue-grey-10",
                                                          size: "33px"
                                                        }
                                                      })
                                                    : _c("q-icon", {
                                                        key:
                                                          "channel-online-off",
                                                        attrs: {
                                                          name:
                                                            "ion-radio-button-on",
                                                          color: _vm.anyDarkmode
                                                            ? ""
                                                            : "blue-grey-10",
                                                          size: "33px"
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-main",
                                            [
                                              _c(
                                                "q-item-tile",
                                                { attrs: { label: "" } },
                                                [
                                                  _vm.product.data.business.hoo[
                                                    _vm.today_day
                                                  ].is24
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-educate"
                                                        },
                                                        [_vm._v("Open")]
                                                      )
                                                    : _vm.product.data.business
                                                        .hoo[_vm.today_day]
                                                        .isClosed
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-protect"
                                                        },
                                                        [_vm._v("Closed")]
                                                      )
                                                    : _vm.today_time_elapsed <
                                                        0 &&
                                                      _vm.today_time_elapsed >
                                                        -10
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-educate"
                                                        },
                                                        [_vm._v("Opening Soon")]
                                                      )
                                                    : _vm.today_time_elapsed <
                                                        0 ||
                                                      _vm.today_time_elapsed >
                                                        100
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-protect"
                                                        },
                                                        [_vm._v("Closed")]
                                                      )
                                                    : _vm.today_time_elapsed >=
                                                      90
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-attention"
                                                        },
                                                        [_vm._v("Closing soon")]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-educate"
                                                        },
                                                        [_vm._v("Open")]
                                                      )
                                                ]
                                              ),
                                              _c(
                                                "q-item-tile",
                                                {
                                                  staticClass: "capitalize",
                                                  attrs: { sublabel: "" }
                                                },
                                                [
                                                  typeof _vm.channel_orders ===
                                                    undefined ||
                                                  _vm.channel_orders === 0 ||
                                                  (_vm.product.data.business
                                                    .hoo[_vm.today_day]
                                                    .isClosed ||
                                                    (_vm.today_time_elapsed <
                                                      0 ||
                                                      _vm.today_time_elapsed >
                                                        100))
                                                    ? [_vm._v("Standard")]
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-protect text-weight-semibold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.getStatusChangeData(
                                                                  _vm.channel_orders
                                                                ).label
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-side",
                                            { attrs: { right: "" } },
                                            [
                                              _c("q-chip", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.clients.length === 0
                                                      ? "•••"
                                                      : _vm.clients.length - 1
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-side",
                                            {
                                              attrs: { right: "" },
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.updateStatusChange()
                                                }
                                              }
                                            },
                                            [
                                              _vm.channel_orders === 0 ||
                                              (_vm.product.data.business.hoo[
                                                _vm.today_day
                                              ].isClosed ||
                                                (_vm.today_time_elapsed < 0 ||
                                                  _vm.today_time_elapsed > 100))
                                                ? _c("q-icon", {
                                                    attrs: {
                                                      name:
                                                        "ion-checkmark-circle",
                                                      color: "educate",
                                                      size: "33px"
                                                    }
                                                  })
                                                : _c("img", {
                                                    attrs: {
                                                      src: _vm.getStatusChangeData(
                                                        _vm.channel_orders
                                                      ).icon,
                                                      width: "33"
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  ),
                                  _c(
                                    "q-list",
                                    {
                                      staticClass:
                                        "text-system-brand text-weight-medium q-list-depth",
                                      staticStyle: {
                                        "max-width": "320px !important",
                                        margin: "30px auto !important"
                                      },
                                      attrs: { highlight: "", link: "" }
                                    },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            mode: "out-in",
                                            appear: "",
                                            "enter-active-class":
                                              "animated fadeIn",
                                            "leave-active-class":
                                              "animated fadeOut"
                                          }
                                        },
                                        [
                                          _vm.requests.length
                                            ? _c("q-progress", {
                                                staticStyle: {
                                                  position: "relative",
                                                  top: "-8px",
                                                  left: "0",
                                                  "margin-bottom": "-4px"
                                                },
                                                attrs: {
                                                  stripe: "",
                                                  indeterminate: "",
                                                  color: "empower",
                                                  height: "4px"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-list-header",
                                        {
                                          staticClass:
                                            "no-margin-top text-weight-bold text-empower module-title-size"
                                        },
                                        [
                                          _c("q-btn", {
                                            staticClass: "float-right",
                                            staticStyle: {
                                              "margin-top": "-10px",
                                              "margin-right": "-10px"
                                            },
                                            attrs: {
                                              round: "",
                                              size: "18px",
                                              "text-color": "empower",
                                              icon: "ion-more"
                                            },
                                            on: {
                                              click: _vm.moduleSettingsLoyalty
                                            }
                                          }),
                                          _c("q-icon", {
                                            staticClass: "on-left-sm",
                                            attrs: {
                                              name: "ion-gift",
                                              size: "32px"
                                            }
                                          }),
                                          _vm._v(" Loyalty\n              ")
                                        ],
                                        1
                                      ),
                                      _c("q-item-separator"),
                                      _c(
                                        "q-item",
                                        {
                                          attrs: {
                                            item: "",
                                            link: "",
                                            disabled: !_vm.loyalty_enabled
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              _vm.loyalty_enabled
                                                ? _vm.loyaltyEnterPIN()
                                                : false
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "q-item-side",
                                            { staticClass: "text-center" },
                                            [
                                              _c("q-icon", {
                                                attrs: {
                                                  name: _vm.loyalty_enabled
                                                    ? "ion-keypad"
                                                    : "ion-eye-off",
                                                  size: "33px",
                                                  color: _vm.anyDarkmode
                                                    ? ""
                                                    : "blue-grey-10"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.loyalty_enabled
                                            ? _c(
                                                "q-item-main",
                                                [
                                                  _c(
                                                    "q-item-tile",
                                                    {
                                                      staticClass: "capitalize",
                                                      attrs: { label: "" }
                                                    },
                                                    [_vm._v("Check PIN")]
                                                  ),
                                                  _c(
                                                    "q-item-tile",
                                                    { attrs: { sublabel: "" } },
                                                    [_vm._v("From customer")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "q-item-main",
                                                [
                                                  _c(
                                                    "q-item-tile",
                                                    {
                                                      staticClass: "capitalize",
                                                      attrs: { label: "" }
                                                    },
                                                    [_vm._v("Disabled")]
                                                  ),
                                                  _c(
                                                    "q-item-tile",
                                                    { attrs: { sublabel: "" } },
                                                    [
                                                      _vm._v(
                                                        "Tap ••• to enable"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                          _c(
                                            "q-item-side",
                                            { attrs: { right: "" } },
                                            [
                                              _c(
                                                "transition",
                                                {
                                                  attrs: {
                                                    mode: "out-in",
                                                    appear: "",
                                                    "enter-active-class":
                                                      "animated slideInRight",
                                                    "leave-active-class":
                                                      "animated slideOutRight"
                                                  }
                                                },
                                                [
                                                  _vm.requests.length
                                                    ? _c("q-icon", {
                                                        attrs: {
                                                          name:
                                                            "ion-checkmark-circle",
                                                          color: "educate",
                                                          size: "33px"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "q-tab-pane",
                        { attrs: { name: "tab-services", "keep-alive": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-center",
                              staticStyle: {
                                position: "relative",
                                "z-index": "100"
                              }
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "text-weight-semibold inline-block text-secondary font-size-100p",
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.serviceComponentCreate($event)
                                    }
                                  }
                                },
                                [
                                  _c("q-icon", {
                                    staticClass: "on-left-sm",
                                    attrs: { name: "ion-settings" }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-system-brand" },
                                    [_vm._v("Add Feature")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "text-weight-semibold inline-block text-secondary font-size-100p",
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.serviceCreationReset()
                                      _vm.dialogServicesAdd()
                                    }
                                  }
                                },
                                [
                                  _c("q-icon", {
                                    staticClass: "on-left-sm",
                                    attrs: { name: "ion-apps" }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-system-brand" },
                                    [_vm._v("Create Station")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "text-weight-semibold inline-block text-secondary font-size-100p",
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.dialogGroupsShow()
                                    }
                                  }
                                },
                                [
                                  _c("q-icon", {
                                    staticClass: "on-left-sm",
                                    attrs: { name: "ion-folder-open" }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-system-brand" },
                                    [_vm._v("Manage Groups")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "text-weight-semibold inline-block text-secondary font-size-100p hidden"
                                },
                                [
                                  _c("q-icon", {
                                    staticClass: "on-left-sm",
                                    attrs: { name: "ion-flask" }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-system-brand" },
                                    [_vm._v("Manage Workflows")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "q-tabs",
                                {
                                  staticClass: "q-tabs-head-hide",
                                  staticStyle: {
                                    "margin-top": "-33px",
                                    "margin-bottom": "40px",
                                    position: "relative",
                                    "z-index": "0"
                                  },
                                  attrs: {
                                    color: "shallow",
                                    "text-color": "tertiary",
                                    animated: ""
                                  }
                                },
                                [
                                  _c(
                                    "q-tab",
                                    {
                                      staticClass:
                                        "text-system-brand text-weight-semibold font-size-120p hidden",
                                      attrs: {
                                        slot: "title",
                                        name: "tab-space-0",
                                        default: ""
                                      },
                                      slot: "title"
                                    },
                                    [_vm._v("All")]
                                  ),
                                  _c(
                                    "q-tab-pane",
                                    {
                                      staticStyle: { "padding-top": "20px" },
                                      attrs: {
                                        name: "tab-space-0",
                                        "keep-alive": ""
                                      }
                                    },
                                    [
                                      _vm.product.data.business.services
                                        ? _c(
                                            "masonry",
                                            {
                                              attrs: {
                                                cols: {
                                                  default: 3,
                                                  900: 2,
                                                  660: 1
                                                },
                                                gutter: { default: 20, 680: 15 }
                                              }
                                            },
                                            [
                                              _vm
                                                .productServicesGroupedByCategories[
                                                "_"
                                              ]
                                                ? _c(
                                                    "q-list",
                                                    {
                                                      staticClass:
                                                        "text-system-brand text-weight-medium q-list-depth",
                                                      staticStyle: {
                                                        "border-color":
                                                          "#a67c474d !important",
                                                        "max-width":
                                                          "320px !important",
                                                        margin:
                                                          "30px auto !important"
                                                      },
                                                      attrs: {
                                                        highlight: "",
                                                        link: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "q-list-header",
                                                        {
                                                          staticClass:
                                                            "no-margin-top text-weight-bold text-value module-title-size text-left"
                                                        },
                                                        [
                                                          _c(
                                                            "q-chip",
                                                            {
                                                              staticClass:
                                                                "float-right",
                                                              attrs: {
                                                                "text-color":
                                                                  "subinfo"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  Object.keys(
                                                                    _vm
                                                                      .productServicesGroupedByCategories[
                                                                      "_"
                                                                    ]
                                                                  ).length
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _c("q-icon", {
                                                            staticClass:
                                                              "on-left-sm",
                                                            attrs: {
                                                              name: "ion-apps",
                                                              size: "32px"
                                                            }
                                                          }),
                                                          _vm._v(
                                                            "\n                      Stations\n                    "
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c("q-item-separator"),
                                                      _vm._l(
                                                        _vm
                                                          .productServicesGroupedByCategories[
                                                          "_"
                                                        ],
                                                        function(service) {
                                                          return _c(
                                                            "q-item",
                                                            {
                                                              key:
                                                                "service-" +
                                                                service.uuid,
                                                              attrs: {
                                                                item: "",
                                                                link: "",
                                                                tag: "label",
                                                                separator: ""
                                                              },
                                                              nativeOn: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.componentUpdate(
                                                                    service.uuid
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "q-item-side",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src:
                                                                        "/statics/services/" +
                                                                        _vm
                                                                          .product
                                                                          .data
                                                                          .business
                                                                          .components[
                                                                          service
                                                                            .icon
                                                                        ]
                                                                          .categoryId +
                                                                        "." +
                                                                        _vm
                                                                          .product
                                                                          .data
                                                                          .business
                                                                          .components[
                                                                          service
                                                                            .icon
                                                                        ]
                                                                          .componentId +
                                                                        ".svg",
                                                                      width:
                                                                        "33"
                                                                    }
                                                                  }),
                                                                  service.private
                                                                    ? _c(
                                                                        "q-icon",
                                                                        {
                                                                          staticClass:
                                                                            "absolute",
                                                                          staticStyle: {
                                                                            top:
                                                                              "8px",
                                                                            left:
                                                                              "38px",
                                                                            height:
                                                                              "20px",
                                                                            border:
                                                                              "4px solid #fff",
                                                                            background:
                                                                              "#fff",
                                                                            "border-radius":
                                                                              "2em"
                                                                          },
                                                                          attrs: {
                                                                            color:
                                                                              "protect",
                                                                            name:
                                                                              "ion-eye-off",
                                                                            size:
                                                                              "20px"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "q-item-main",
                                                                [
                                                                  _c(
                                                                    "q-item-tile",
                                                                    {
                                                                      staticClass:
                                                                        "capitalize text-weight-semibold",
                                                                      attrs: {
                                                                        label:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      service.isNew
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "NEW"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            service.label
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  service.dependencies &&
                                                                  service
                                                                    .dependencies
                                                                    .length
                                                                    ? _c(
                                                                        "q-item-tile",
                                                                        {
                                                                          staticClass:
                                                                            "text-fixedwidth-brand text-weight-bold",
                                                                          attrs: {
                                                                            sublabel:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "q-chip",
                                                                            {
                                                                              staticClass:
                                                                                "q-chip-icon-dark text-weight-semibold q-chip-padded",
                                                                              attrs: {
                                                                                detail:
                                                                                  "",
                                                                                square:
                                                                                  "",
                                                                                color:
                                                                                  "purple-pale",
                                                                                icon:
                                                                                  "ion-git-pull-request",
                                                                                dense:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            " +
                                                                                  _vm._s(
                                                                                    service
                                                                                      .dependencies
                                                                                      .length
                                                                                  ) +
                                                                                  " " +
                                                                                  _vm._s(
                                                                                    service
                                                                                      .dependencies
                                                                                      .length ===
                                                                                      1
                                                                                      ? "dependency"
                                                                                      : "dependencies"
                                                                                  ) +
                                                                                  "\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "q-item-tile",
                                                                    {
                                                                      staticClass:
                                                                        "text-fixedwidth-brand text-weight-bold",
                                                                      attrs: {
                                                                        sublabel:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "q-chip",
                                                                        {
                                                                          staticClass:
                                                                            "q-chip-icon-dark text-weight-semibold q-chip-padded",
                                                                          attrs: {
                                                                            detail:
                                                                              "",
                                                                            square:
                                                                              "",
                                                                            color:
                                                                              "shallower",
                                                                            icon:
                                                                              "ion-apps",
                                                                            dense:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                service
                                                                                  .components
                                                                                  .length
                                                                              ) +
                                                                              " features\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  service.refId
                                                                    ? _c(
                                                                        "q-item-tile",
                                                                        {
                                                                          staticClass:
                                                                            "text-fixedwidth-brand text-weight-bold",
                                                                          attrs: {
                                                                            sublabel:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "q-chip",
                                                                            {
                                                                              staticClass:
                                                                                "q-chip-icon-dark text-weight-semibold q-chip-padded",
                                                                              attrs: {
                                                                                detail:
                                                                                  "",
                                                                                square:
                                                                                  "",
                                                                                color:
                                                                                  "shallower",
                                                                                icon:
                                                                                  "ion-grid",
                                                                                dense:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            " +
                                                                                  _vm._s(
                                                                                    service.refId
                                                                                  ) +
                                                                                  "\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "q-item-tile",
                                                                    {
                                                                      staticClass:
                                                                        "text-fixedwidth-brand text-weight-bold",
                                                                      attrs: {
                                                                        sublabel:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "q-chip",
                                                                        {
                                                                          staticClass:
                                                                            "q-chip-icon-dark text-weight-semibold q-chip-padded",
                                                                          attrs: {
                                                                            detail:
                                                                              "",
                                                                            square:
                                                                              "",
                                                                            color:
                                                                              "shallower",
                                                                            icon:
                                                                              "ion-pulse",
                                                                            dense:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "dtformat"
                                                                                )(
                                                                                  _vm.serviceLastUpdate(
                                                                                    service.uuid
                                                                                  )
                                                                                )
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "q-item-side",
                                                                {
                                                                  attrs: {
                                                                    right: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm.serviceStatus(
                                                                    service.uuid
                                                                  ) === 0
                                                                    ? _c(
                                                                        "q-icon",
                                                                        {
                                                                          attrs: {
                                                                            name:
                                                                              "ion-checkmark-circle",
                                                                            color:
                                                                              "educate",
                                                                            size:
                                                                              "32px"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm.serviceStatus(
                                                                        service.uuid
                                                                      ) === 1
                                                                    ? _c(
                                                                        "q-icon",
                                                                        {
                                                                          attrs: {
                                                                            name:
                                                                              "ion-alert",
                                                                            color:
                                                                              "attention",
                                                                            size:
                                                                              "32px"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm.serviceStatus(
                                                                        service.uuid
                                                                      ) === 2
                                                                    ? _c(
                                                                        "q-icon",
                                                                        {
                                                                          attrs: {
                                                                            name:
                                                                              "ion-close-circle",
                                                                            color:
                                                                              "protect",
                                                                            size:
                                                                              "32px"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm.serviceStatus(
                                                                        service.uuid
                                                                      ) === 3
                                                                    ? _c(
                                                                        "q-icon",
                                                                        {
                                                                          staticClass:
                                                                            "statusFader",
                                                                          attrs: {
                                                                            name:
                                                                              "ion-code-working",
                                                                            color:
                                                                              "purple-l2",
                                                                            size:
                                                                              "32px"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "q-icon",
                                                                        {
                                                                          attrs: {
                                                                            name:
                                                                              "ion-code",
                                                                            color:
                                                                              "shallow",
                                                                            size:
                                                                              "32px"
                                                                          }
                                                                        }
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _c("q-item-separator")
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.productServicesGroupedByCategories,
                                                function(
                                                  services,
                                                  serviceCategory
                                                ) {
                                                  return serviceCategory !== "_"
                                                    ? _c(
                                                        "q-list",
                                                        {
                                                          key:
                                                            "" +
                                                            serviceCategory,
                                                          staticClass:
                                                            "text-system-brand text-weight-medium q-list-depth",
                                                          staticStyle: {
                                                            "max-width":
                                                              "320px !important",
                                                            margin:
                                                              "30px auto !important"
                                                          },
                                                          attrs: {
                                                            highlight: "",
                                                            link: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "q-list-header",
                                                            {
                                                              staticClass:
                                                                "no-margin-top text-weight-bold text-empower module-title-size text-left"
                                                            },
                                                            [
                                                              _c(
                                                                "q-chip",
                                                                {
                                                                  staticClass:
                                                                    "float-right",
                                                                  attrs: {
                                                                    "text-color":
                                                                      "subinfo"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      Object.keys(
                                                                        services
                                                                      ).length
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c("q-icon", {
                                                                staticClass:
                                                                  "on-left-sm",
                                                                attrs: {
                                                                  name:
                                                                    "ion-settings",
                                                                  size: "32px"
                                                                }
                                                              }),
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.getServiceCategoryLabel(
                                                                      serviceCategory
                                                                    )
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._l(
                                                            _vm.componentsGroupedByComponents(
                                                              services
                                                            ),
                                                            function(
                                                              servicesByComponent,
                                                              componentId
                                                            ) {
                                                              return _vm.Wings
                                                                .services.list[
                                                                _vm.product.data
                                                                  .business
                                                                  .components[
                                                                  servicesByComponent[
                                                                    Object.keys(
                                                                      servicesByComponent
                                                                    )[0]
                                                                  ]
                                                                    .components[0]
                                                                ].categoryId
                                                              ]
                                                                ? [
                                                                    _c(
                                                                      "q-item-separator"
                                                                    ),
                                                                    _c(
                                                                      "q-list-header",
                                                                      {
                                                                        staticClass:
                                                                          "text-weight-bold text-grey text-left"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                      " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .Wings
                                                                                .services
                                                                                .list[
                                                                                _vm
                                                                                  .product
                                                                                  .data
                                                                                  .business
                                                                                  .components[
                                                                                  servicesByComponent[
                                                                                    Object.keys(
                                                                                      servicesByComponent
                                                                                    )[0]
                                                                                  ]
                                                                                    .components[0]
                                                                                ]
                                                                                  .categoryId
                                                                              ][
                                                                                componentId
                                                                              ]
                                                                                .name
                                                                            ) +
                                                                            "\n                    "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      servicesByComponent,
                                                                      function(
                                                                        service
                                                                      ) {
                                                                        return _c(
                                                                          "q-item",
                                                                          {
                                                                            key:
                                                                              "service-" +
                                                                              service.uuid,
                                                                            attrs: {
                                                                              item:
                                                                                "",
                                                                              link:
                                                                                "",
                                                                              tag:
                                                                                "label"
                                                                            },
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.componentUpdate(
                                                                                  service.uuid
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "q-item-side",
                                                                              {
                                                                                staticClass:
                                                                                  "text-center"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    attrs: {
                                                                                      src:
                                                                                        "/statics/services/" +
                                                                                        _vm
                                                                                          .product
                                                                                          .data
                                                                                          .business
                                                                                          .components[
                                                                                          service
                                                                                            .components[0]
                                                                                        ]
                                                                                          .categoryId +
                                                                                        "." +
                                                                                        _vm
                                                                                          .product
                                                                                          .data
                                                                                          .business
                                                                                          .components[
                                                                                          service
                                                                                            .components[0]
                                                                                        ]
                                                                                          .componentId +
                                                                                        ".svg",
                                                                                      width:
                                                                                        "33"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                service.private
                                                                                  ? _c(
                                                                                      "q-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "absolute",
                                                                                        staticStyle: {
                                                                                          top:
                                                                                            "8px",
                                                                                          left:
                                                                                            "38px",
                                                                                          height:
                                                                                            "20px",
                                                                                          border:
                                                                                            "4px solid #fff",
                                                                                          background:
                                                                                            "#fff",
                                                                                          "border-radius":
                                                                                            "2em"
                                                                                        },
                                                                                        attrs: {
                                                                                          color:
                                                                                            "protect",
                                                                                          name:
                                                                                            "ion-eye-off",
                                                                                          size:
                                                                                            "20px"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _vm._e()
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "q-item-main",
                                                                              [
                                                                                _c(
                                                                                  "q-item-tile",
                                                                                  {
                                                                                    staticClass:
                                                                                      "capitalize text-weight-semibold",
                                                                                    attrs: {
                                                                                      label:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    service.isNew
                                                                                      ? _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "NEW"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm._v(
                                                                                      "\n                          " +
                                                                                        _vm._s(
                                                                                          service.label
                                                                                        ) +
                                                                                        "\n                          "
                                                                                    ),
                                                                                    !service.label
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "text-shadow"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm
                                                                                                  .Wings
                                                                                                  .services
                                                                                                  .list[
                                                                                                  _vm
                                                                                                    .product
                                                                                                    .data
                                                                                                    .business
                                                                                                    .components[
                                                                                                    service
                                                                                                      .components[0]
                                                                                                  ]
                                                                                                    .categoryId
                                                                                                ][
                                                                                                  _vm
                                                                                                    .product
                                                                                                    .data
                                                                                                    .business
                                                                                                    .components[
                                                                                                    service
                                                                                                      .components[0]
                                                                                                  ]
                                                                                                    .componentId
                                                                                                ]
                                                                                                  .name
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                ),
                                                                                _vm
                                                                                  .product
                                                                                  .data
                                                                                  .business
                                                                                  .components[
                                                                                  service
                                                                                    .components[0]
                                                                                ]
                                                                                  .isDev
                                                                                  ? _c(
                                                                                      "q-item-tile",
                                                                                      {
                                                                                        attrs: {
                                                                                          sublabel:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "q-chip",
                                                                                          {
                                                                                            staticClass:
                                                                                              "q-chip-icon-dark text-weight-semibold q-chip-padded",
                                                                                            attrs: {
                                                                                              detail:
                                                                                                "",
                                                                                              square:
                                                                                                "",
                                                                                              color:
                                                                                                "shallower",
                                                                                              icon:
                                                                                                "ion-swap",
                                                                                              dense:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                            API\n                          "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                                service.dependencies &&
                                                                                service
                                                                                  .dependencies
                                                                                  .length
                                                                                  ? _c(
                                                                                      "q-item-tile",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-fixedwidth-brand text-weight-bold",
                                                                                        attrs: {
                                                                                          sublabel:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "q-chip",
                                                                                          {
                                                                                            staticClass:
                                                                                              "q-chip-icon-dark text-weight-semibold q-chip-padded",
                                                                                            attrs: {
                                                                                              detail:
                                                                                                "",
                                                                                              square:
                                                                                                "",
                                                                                              color:
                                                                                                "purple-pale",
                                                                                              icon:
                                                                                                "ion-git-pull-request",
                                                                                              dense:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                            " +
                                                                                                _vm._s(
                                                                                                  service
                                                                                                    .dependencies
                                                                                                    .length
                                                                                                ) +
                                                                                                " " +
                                                                                                _vm._s(
                                                                                                  service
                                                                                                    .dependencies
                                                                                                    .length ===
                                                                                                    1
                                                                                                    ? "dependency"
                                                                                                    : "dependencies"
                                                                                                ) +
                                                                                                "\n                          "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                                service.refId
                                                                                  ? _c(
                                                                                      "q-item-tile",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text-fixedwidth-brand text-weight-bold",
                                                                                        attrs: {
                                                                                          sublabel:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "q-chip",
                                                                                          {
                                                                                            staticClass:
                                                                                              "q-chip-icon-dark text-weight-semibold q-chip-padded",
                                                                                            attrs: {
                                                                                              detail:
                                                                                                "",
                                                                                              square:
                                                                                                "",
                                                                                              color:
                                                                                                "shallower",
                                                                                              icon:
                                                                                                "ion-grid",
                                                                                              dense:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                            " +
                                                                                                _vm._s(
                                                                                                  service.refId
                                                                                                ) +
                                                                                                "\n                          "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _c(
                                                                                  "q-item-tile",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-fixedwidth-brand text-weight-bold",
                                                                                    attrs: {
                                                                                      sublabel:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "q-chip",
                                                                                      {
                                                                                        staticClass:
                                                                                          "q-chip-icon-dark text-weight-semibold q-chip-padded",
                                                                                        attrs: {
                                                                                          detail:
                                                                                            "",
                                                                                          square:
                                                                                            "",
                                                                                          color:
                                                                                            "shallower",
                                                                                          icon:
                                                                                            "ion-pulse",
                                                                                          dense:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                            " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "dtformat"
                                                                                              )(
                                                                                                _vm.serviceLastUpdate(
                                                                                                  service.uuid
                                                                                                )
                                                                                              )
                                                                                            ) +
                                                                                            "\n                          "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "q-item-side",
                                                                              {
                                                                                attrs: {
                                                                                  right:
                                                                                    ""
                                                                                },
                                                                                nativeOn: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    $event.stopPropagation()
                                                                                    return _vm.changeComponentStatus(
                                                                                      _vm
                                                                                        .product
                                                                                        .data
                                                                                        .business
                                                                                        .components[
                                                                                        service
                                                                                          .components[0]
                                                                                      ],
                                                                                      service.uuid
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm.serviceStatus(
                                                                                  service.uuid
                                                                                ) ===
                                                                                0
                                                                                  ? _c(
                                                                                      "q-icon",
                                                                                      {
                                                                                        attrs: {
                                                                                          name:
                                                                                            "ion-checkmark-circle",
                                                                                          color:
                                                                                            "educate",
                                                                                          size:
                                                                                            "32px"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _vm.serviceStatus(
                                                                                      service.uuid
                                                                                    ) ===
                                                                                    1
                                                                                  ? _c(
                                                                                      "q-icon",
                                                                                      {
                                                                                        attrs: {
                                                                                          name:
                                                                                            "ion-alert",
                                                                                          color:
                                                                                            "attention",
                                                                                          size:
                                                                                            "32px"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _vm.serviceStatus(
                                                                                      service.uuid
                                                                                    ) ===
                                                                                    2
                                                                                  ? _c(
                                                                                      "q-icon",
                                                                                      {
                                                                                        attrs: {
                                                                                          name:
                                                                                            "ion-close-circle",
                                                                                          color:
                                                                                            "protect",
                                                                                          size:
                                                                                            "32px"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _vm.serviceStatus(
                                                                                      service.uuid
                                                                                    ) ===
                                                                                    3
                                                                                  ? _c(
                                                                                      "q-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "statusFader",
                                                                                        attrs: {
                                                                                          name:
                                                                                            "ion-code-working",
                                                                                          color:
                                                                                            "purple-l2",
                                                                                          size:
                                                                                            "32px"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _c(
                                                                                      "q-icon",
                                                                                      {
                                                                                        attrs: {
                                                                                          name:
                                                                                            "ion-code",
                                                                                          color:
                                                                                            "shallow",
                                                                                          size:
                                                                                            "32px"
                                                                                        }
                                                                                      }
                                                                                    )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    )
                                                                  ]
                                                                : _vm._e()
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e()
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-tab-pane",
                                    {
                                      attrs: {
                                        name: "tab-space-1",
                                        "keep-alive": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "on-top-xx text-system-brand text-weight-semibold text-subinfo-l"
                                        },
                                        [_vm._v("No Services")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "q-tab-pane",
                                    {
                                      attrs: {
                                        name: "tab-space-2",
                                        "keep-alive": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "on-top-xx text-system-brand text-weight-semibold text-subinfo-l"
                                        },
                                        [_vm._v("No Services")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "q-tab-pane",
                        { attrs: { name: "tab-team", "keep-alive": "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "text-weight-semibold inline-block text-secondary font-size-100p"
                                },
                                [
                                  _c("q-icon", {
                                    staticClass: "on-left-sm",
                                    attrs: { name: "ion-add-circle" }
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-system-brand" },
                                    [_vm._v("Add User")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "on-top-lg module-grid-layout" },
                            [
                              _c(
                                "q-list",
                                {
                                  staticClass:
                                    "text-system-brand text-weight-medium q-list-depth",
                                  attrs: { highlight: "", link: "" }
                                },
                                [
                                  _c(
                                    "q-list-header",
                                    {
                                      staticClass:
                                        "no-margin-top text-weight-bold text-empower module-title-size"
                                    },
                                    [
                                      _c("q-btn", {
                                        staticClass: "float-right",
                                        staticStyle: {
                                          "margin-top": "-10px",
                                          "margin-right": "-10px"
                                        },
                                        attrs: {
                                          round: "",
                                          size: "18px",
                                          "text-color": "empower",
                                          icon: "ion-more"
                                        }
                                      }),
                                      _c("q-icon", {
                                        staticClass: "on-left-sm",
                                        attrs: {
                                          name: "ion-people",
                                          size: "32px"
                                        }
                                      }),
                                      _vm._v(" Team\n              ")
                                    ],
                                    1
                                  ),
                                  _c("q-item-separator"),
                                  _c(
                                    "q-item",
                                    {
                                      attrs: {
                                        item: "",
                                        link: "",
                                        tag: "label"
                                      }
                                    },
                                    [
                                      _c(
                                        "q-item-side",
                                        { staticClass: "text-center" },
                                        [
                                          _c("q-icon", {
                                            staticClass: "on-left-sm",
                                            attrs: {
                                              color: "educate",
                                              name: "ion-medal",
                                              size: "33px"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-main",
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass: "capitalize",
                                              attrs: { label: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Haitham Al Beik\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "q-item-tile",
                                            { attrs: { sublabel: "" } },
                                            [
                                              _vm._v(
                                                "\n                    +1 508 847 8747\n                    "
                                              ),
                                              _c(
                                                "q-chip",
                                                {
                                                  staticClass: "on-right-xs",
                                                  attrs: { dense: "" }
                                                },
                                                [_vm._v("admin")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item",
                                    {
                                      attrs: {
                                        item: "",
                                        link: "",
                                        tag: "label"
                                      }
                                    },
                                    [
                                      _c(
                                        "q-item-side",
                                        { staticClass: "text-center" },
                                        [
                                          _c("q-icon", {
                                            staticClass: "on-left-sm",
                                            attrs: {
                                              name: "ion-contact",
                                              size: "33px"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-main",
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass: "capitalize",
                                              attrs: { label: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Kelsey Peel\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "q-item-tile",
                                            { attrs: { sublabel: "" } },
                                            [
                                              _vm._v(
                                                "\n                    +1 774 245 5299\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-page-sticky",
            { attrs: { position: "bottom-right", offset: [20, 20] } },
            [
              _c(
                "transition",
                {
                  attrs: {
                    appear: "",
                    "enter-active-class": "animated fadeInUp animated400",
                    "leave-active-class": "animated fadeOutDown animated400"
                  }
                },
                [
                  _c(
                    "q-btn",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      staticClass: "shadow-24",
                      attrs: {
                        size: "lg",
                        round: "",
                        push: "",
                        color: "primary"
                      },
                      on: { click: _vm.qrcode }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/statics/_demo/qrcode_white.svg",
                          width: "28"
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              attrs: { minimized: "" },
              model: {
                value: _vm.dialogModuleStatsLoyaltyTodayShow,
                callback: function($$v) {
                  _vm.dialogModuleStatsLoyaltyTodayShow = $$v
                },
                expression: "dialogModuleStatsLoyaltyTodayShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    { attrs: { slot: "header", inverted: "" }, slot: "header" },
                    [_c("q-toolbar-title", [_vm._v("Rewards Today")])],
                    1
                  ),
                  _c("div", { staticClass: "layout-padding" }, [
                    _vm.dialogModuleStatsLoyaltyTodayData === null
                      ? _c(
                          "div",
                          { staticClass: "layout-padding" },
                          [
                            _c("q-progress", {
                              attrs: {
                                indeterminate: "",
                                color: "secondary",
                                stripe: "",
                                animate: "",
                                height: "12px"
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticStyle: { "margin-top": "-40px" } },
                          [
                            _c(
                              "q-list",
                              {
                                staticClass:
                                  "card text-system-brand text-weight-medium full-width on-top-lg",
                                attrs: { "no-border": "" }
                              },
                              [
                                _c(
                                  "q-list-header",
                                  {
                                    staticClass:
                                      "text-weight-bold text-grey text-left"
                                  },
                                  [_vm._v(_vm._s(_vm.today_date))]
                                ),
                                _vm.dialogModuleStatsLoyaltyTodayData ===
                                  false ||
                                !_vm.dialogModuleStatsLoyaltyTodayData.length
                                  ? _c(
                                      "q-item",
                                      {
                                        staticClass: "text-center",
                                        attrs: { disabled: "" }
                                      },
                                      [
                                        _c(
                                          "q-item-main",
                                          { staticClass: "text-center" },
                                          [
                                            _vm._v(
                                              "\n                No rewards at this time.\n              "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._l(
                                      _vm.dialogModuleStatsLoyaltyTodayData,
                                      function(i) {
                                        return _c(
                                          "q-item",
                                          { key: "dll-" + i },
                                          [
                                            _c(
                                              "q-item-main",
                                              [
                                                i.data
                                                  ? _c(
                                                      "q-item-tile",
                                                      {
                                                        staticClass:
                                                          "capitalize",
                                                        attrs: { label: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            i.data.split("_")[1]
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "q-item-tile",
                                                      { attrs: { label: "" } },
                                                      [_vm._v("Rewards")]
                                                    )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-side",
                                              { attrs: { right: "" } },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(i.total) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    )
                              ],
                              2
                            )
                          ],
                          1
                        )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              attrs: {
                minimized: "",
                "no-esc-dismiss": _vm.dialogRequestPinCheckingKeep,
                "no-backdrop-dismiss": _vm.dialogRequestPinCheckingKeep
              },
              model: {
                value: _vm.dialogRequestPinShow,
                callback: function($$v) {
                  _vm.dialogRequestPinShow = $$v
                },
                expression: "dialogRequestPinShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    { attrs: { slot: "header", inverted: "" }, slot: "header" },
                    [_c("q-toolbar-title", [_vm._v("Loyalty Pin")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    loading: _vm.dialogRequestPinChecking,
                                    disabled:
                                      _vm.dialogRequestPinValue.length < 4,
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.loyaltyCheckPIN($event)
                                    }
                                  }
                                },
                                [_vm._v("\n              CHECK\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("q-toggle", {
                        attrs: {
                          "checked-icon": "ion-ios-lock",
                          "unchecked-icon": "ion-ios-unlock",
                          dark: _vm.anyDarkmode
                        },
                        model: {
                          value: _vm.dialogRequestPinCheckingKeep,
                          callback: function($$v) {
                            _vm.dialogRequestPinCheckingKeep = $$v
                          },
                          expression: "dialogRequestPinCheckingKeep"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding" },
                    [
                      _c("PincodeInput", {
                        attrs: {
                          disabled: _vm.dialogRequestPinChecking,
                          autofocus: true,
                          placeholder: "",
                          dark: _vm.anyDarkmode
                        },
                        model: {
                          value: _vm.dialogRequestPinValue,
                          callback: function($$v) {
                            _vm.dialogRequestPinValue = $$v
                          },
                          expression: "dialogRequestPinValue"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogRequest", position: "bottom" },
              model: {
                value: _vm.dialogRequestShow,
                callback: function($$v) {
                  _vm.dialogRequestShow = $$v
                },
                expression: "dialogRequestShow"
              }
            },
            [
              _vm.dialogRequestIndex !== null &&
              _vm.requests[_vm.dialogRequestIndex]
                ? _c(
                    "q-modal-layout",
                    [
                      _c(
                        "q-toolbar",
                        {
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c("q-toolbar-title", [
                            _vm._v(
                              "#" +
                                _vm._s(
                                  _vm.requests[_vm.dialogRequestIndex].info.pin
                                )
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "q-toolbar",
                        {
                          staticClass: "toolbar-overscroll-shadow",
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "primary-light",
                                        "text-color": "primary",
                                        rounded: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialogRequestShow = false
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/statics/_demo/" +
                                            (_vm.anyDarkmode
                                              ? "chevron.compact.down_white.svg"
                                              : "chevron.compact.down_primary.svg"),
                                          height: "10"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "margin-auto-lr text-system-brand layout-padding no-padding-tb row justify-center items-center",
                          staticStyle: { "max-width": "500px" }
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "font-size-180p margin-auto-lr limit-width-1024 full-width full-height text-system-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color:
                                          _vm.requests[_vm.dialogRequestIndex]
                                            .info.stamps ===
                                          _vm.product.data.business.loyalty
                                            .stamps
                                            ? "value"
                                            : "standout",
                                        "text-color": "white",
                                        rounded: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.loyaltyStatusUpdate(
                                            _vm.requests[_vm.dialogRequestIndex]
                                              .info.stamps + 1
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.requests[_vm.dialogRequestIndex]
                                              .info.stamps ===
                                              _vm.product.data.business.loyalty
                                                .stamps
                                              ? "FREE ITEM"
                                              : "+1"
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.requests[_vm.dialogRequestIndex].info.stamps ===
                          _vm.product.data.business.loyalty.stamps
                            ? _c(
                                "q-card",
                                {
                                  staticClass:
                                    "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                                },
                                [
                                  _c(
                                    "q-card-main",
                                    {
                                      staticClass:
                                        "column justify-center full-height"
                                    },
                                    [
                                      _c(
                                        "q-btn",
                                        {
                                          staticClass:
                                            "font-size-180p margin-auto-lr limit-width-1024 full-width full-height text-system-brand text-weight-semibold uppercase",
                                          attrs: {
                                            color: "standout",
                                            "text-color": "white",
                                            rounded: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loyaltyStatusCarryover(
                                                _vm.requests[
                                                  _vm.dialogRequestIndex
                                                ].info.stamps + 1
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              CARRYOVER\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.requests[_vm.dialogRequestIndex].info.carryover
                            ? _c(
                                "div",
                                { staticClass: "margin-auto-lr text-center" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.requests[_vm.dialogRequestIndex]
                                          .info.carryover
                                      ) + " Free Items"
                                    )
                                  ]),
                                  _vm._l(
                                    _vm.requests[_vm.dialogRequestIndex].info
                                      .carryover,
                                    function(i) {
                                      return [
                                        _c(
                                          "q-btn",
                                          {
                                            key: "loyalty-ctr-co-btn-" + i,
                                            staticClass:
                                              "text-family-brand text-weight-semibold font-size-140p bg-value-gradient",
                                            staticStyle: { margin: "10px" },
                                            attrs: {
                                              "bg-color": "value-gradient",
                                              outline: "",
                                              rounded: "",
                                              icon: "ion-gift"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.loyaltyStatusUseCarryover(
                                                  i
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("×" + _vm._s(i))]
                                        )
                                      ]
                                    }
                                  ),
                                  _c("q-card-separator", {
                                    staticStyle: {
                                      "margin-bottom": "20px",
                                      "margin-top": "20px"
                                    }
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "margin-auto-lr text-center" },
                            [
                              _vm._l(
                                _vm.product.data.business.loyalty.stamps,
                                function(i) {
                                  return [
                                    i >
                                    _vm.requests[_vm.dialogRequestIndex].info
                                      .stamps
                                      ? _c(
                                          "q-btn",
                                          {
                                            key: "loyalty-ctr-" + i,
                                            staticClass:
                                              "text-family-brand text-weight-semibold font-size-160p",
                                            staticStyle: { margin: "10px" },
                                            attrs: { outline: "", round: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loyaltyStatusUpdate(
                                                  i
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(i))]
                                        )
                                      : _c(
                                          "q-btn",
                                          {
                                            key: "loyalty-ctr-dis-" + i,
                                            staticClass:
                                              "text-family-brand text-weight-semibold font-size-160p",
                                            staticStyle: { margin: "10px" },
                                            attrs: {
                                              disabled: "",
                                              outline: "",
                                              round: ""
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  "/statics/_demo/checkmark.circle.fill.svg"
                                              }
                                            })
                                          ]
                                        )
                                  ]
                                }
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: {
                id: "dialogModuleServicesStatusUpdate",
                position: "bottom"
              },
              model: {
                value: _vm.dialogServicesSpacesShow,
                callback: function($$v) {
                  _vm.dialogServicesSpacesShow = $$v
                },
                expression: "dialogServicesSpacesShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("manage spaces")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.dialogSpacesManage($event)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center text-shallow text-weight-semibold"
                        },
                        [_vm._v("Coming Soon")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: {
                id: "dialogModuleServicesStatusUpdate",
                position: "bottom"
              },
              model: {
                value: _vm.dialogServicesStatusUpdateShow,
                callback: function($$v) {
                  _vm.dialogServicesStatusUpdateShow = $$v
                },
                expression: "dialogServicesStatusUpdateShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("modify service")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    loading: _vm.dialogServicesDuplicating,
                                    color: "empower-light",
                                    "text-color": "empower",
                                    rounded: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.duplicateService()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("DUPLICATE")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.componentUpdate()
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.dialogServicesUpdateSelection
                    ? _c(
                        "div",
                        { staticClass: "layout-padding no-padding-top" },
                        [
                          _c("q-scroll-observable", {
                            on: { scroll: _vm.toolbarShadowOnOverscroll }
                          }),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-grey text-left"
                                },
                                [_vm._v("Availability")]
                              ),
                              _c(
                                "q-item",
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/statics/services/" +
                                            _vm.dialogServicesUpdateSelection
                                              .iconDetails.categoryId +
                                            "." +
                                            _vm.dialogServicesUpdateSelection
                                              .iconDetails.componentId +
                                            ".svg",
                                          width: "64"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass:
                                            "text-weight-semibold font-size-120p",
                                          attrs: { label: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getServiceName(
                                                  _vm.dialogServicesUpdateSelection
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm.dialogServicesUpdateSelection
                                        .components.length > 1
                                        ? _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "capitalize text-weight-semibold font-size-120p",
                                              attrs: { label: "" }
                                            },
                                            [
                                              _vm.dialogServicesUpdateSelection
                                                .statusDetails.status === 0
                                                ? _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-educate"
                                                    },
                                                    [_vm._v("Fully Available")]
                                                  )
                                                : _vm
                                                    .dialogServicesUpdateSelection
                                                    .statusDetails.status === 1
                                                ? _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-attention"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Partially Available"
                                                      )
                                                    ]
                                                  )
                                                : _vm
                                                    .dialogServicesUpdateSelection
                                                    .statusDetails.status === 2
                                                ? _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-protect"
                                                    },
                                                    [_vm._v("Not Available")]
                                                  )
                                                : _vm
                                                    .dialogServicesUpdateSelection
                                                    .statusDetails.status === 3
                                                ? _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "statusFinder text-purple-l2"
                                                    },
                                                    [_vm._v("Maintenance")]
                                                  )
                                                : _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-shallow"
                                                    },
                                                    [_vm._v("Not Set")]
                                                  )
                                            ]
                                          )
                                        : _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "capitalize text-weight-semibold font-size-120p",
                                              attrs: { label: "" }
                                            },
                                            [
                                              _vm.dialogServicesUpdateSelection
                                                .statusDetails.status === 0
                                                ? _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-educate"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.getComponentDescriptors(
                                                              _vm
                                                                .dialogServicesUpdateSelection
                                                                .componentsDetails[
                                                                _vm
                                                                  .dialogServicesUpdateSelection
                                                                  .components[0]
                                                              ]
                                                            ).availability[0]
                                                              .label
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                : _vm
                                                    .dialogServicesUpdateSelection
                                                    .statusDetails.status === 1
                                                ? _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-attention"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.getComponentDescriptors(
                                                              _vm
                                                                .dialogServicesUpdateSelection
                                                                .componentsDetails[
                                                                _vm
                                                                  .dialogServicesUpdateSelection
                                                                  .components[0]
                                                              ]
                                                            ).availability[1]
                                                              .label
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                : _vm
                                                    .dialogServicesUpdateSelection
                                                    .statusDetails.status === 2
                                                ? _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-protect"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.getComponentDescriptors(
                                                              _vm
                                                                .dialogServicesUpdateSelection
                                                                .componentsDetails[
                                                                _vm
                                                                  .dialogServicesUpdateSelection
                                                                  .components[0]
                                                              ]
                                                            ).availability[2]
                                                              .label
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                : _vm
                                                    .dialogServicesUpdateSelection
                                                    .statusDetails.status === 3
                                                ? _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "statusFinder text-purple-l2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.getComponentDescriptors(
                                                              _vm
                                                                .dialogServicesUpdateSelection
                                                                .componentsDetails[
                                                                _vm
                                                                  .dialogServicesUpdateSelection
                                                                  .components[0]
                                                              ]
                                                            ).availability[3]
                                                              .label
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "text-shallow"
                                                    },
                                                    [_vm._v("Not Set")]
                                                  )
                                            ]
                                          ),
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "text-purple-l2",
                                          attrs: { sublabel: "" }
                                        },
                                        [
                                          _c(
                                            "q-chip",
                                            {
                                              staticClass:
                                                "margin-xs-l q-chip-computed text-weight-semibold",
                                              attrs: {
                                                detail: "",
                                                square: "",
                                                color: "empower",
                                                icon:
                                                  _vm
                                                    .dialogServicesUpdateSelection
                                                    .components.length > 1
                                                    ? "ion-apps"
                                                    : "ion-cog",
                                                pointing: "left",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm
                                                      .dialogServicesUpdateSelection
                                                      .components.length
                                                  ) +
                                                  "\n                  feature" +
                                                  _vm._s(
                                                    _vm
                                                      .dialogServicesUpdateSelection
                                                      .components.length > 1
                                                      ? "s"
                                                      : ""
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _vm.dialogServicesUpdateSelection
                                            .dependencies.length
                                            ? _c(
                                                "q-chip",
                                                {
                                                  staticClass:
                                                    "margin-xs-l q-chip-computed text-weight-semibold",
                                                  attrs: {
                                                    detail: "",
                                                    square: "",
                                                    color: "purple-l2",
                                                    icon:
                                                      "ion-git-pull-request",
                                                    pointing: "left",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm
                                                          .dialogServicesUpdateSelection
                                                          .dependencies.length
                                                      ) +
                                                      "\n                  " +
                                                      _vm._s(
                                                        _vm
                                                          .dialogServicesUpdateSelection
                                                          .dependencies
                                                          .length === 1
                                                          ? "dependency"
                                                          : "dependencies"
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass:
                                            "text-fixedwidth-brand text-weight-bold text-subinfo-l",
                                          attrs: { sublabel: "" }
                                        },
                                        [
                                          _c("q-icon", {
                                            staticStyle: {
                                              "margin-right": "-3px",
                                              position: "relative",
                                              top: "-1px"
                                            },
                                            attrs: {
                                              name: "ion-pulse",
                                              size: "20px"
                                            }
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("dtformat")(
                                                  _vm.serviceLastUpdate(
                                                    _vm
                                                      .dialogServicesUpdateSelection
                                                      .uuid
                                                  )
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    { attrs: { right: "" } },
                                    [
                                      _vm.dialogServicesUpdateSelection
                                        .statusDetails.status === 0
                                        ? _c("q-icon", {
                                            staticStyle: { width: "64px" },
                                            attrs: {
                                              name: "ion-checkmark-circle",
                                              color: "educate",
                                              size: "64px"
                                            }
                                          })
                                        : _vm.dialogServicesUpdateSelection
                                            .statusDetails.status === 1
                                        ? _c("q-icon", {
                                            staticStyle: { width: "64px" },
                                            attrs: {
                                              name: "ion-alert",
                                              color: "attention",
                                              size: "64px"
                                            }
                                          })
                                        : _vm.dialogServicesUpdateSelection
                                            .statusDetails.status === 2
                                        ? _c("q-icon", {
                                            staticStyle: { width: "64px" },
                                            attrs: {
                                              name: "ion-close-circle",
                                              color: "protect",
                                              size: "64px"
                                            }
                                          })
                                        : _vm.dialogServicesUpdateSelection
                                            .statusDetails.status === 3
                                        ? _c("q-icon", {
                                            staticClass: "statusFader",
                                            staticStyle: { width: "64px" },
                                            attrs: {
                                              name: "ion-code-working",
                                              color: "purple-l2",
                                              size: "64px"
                                            }
                                          })
                                        : _c("q-icon", {
                                            staticStyle: { width: "64px" },
                                            attrs: {
                                              name: "ion-code",
                                              color: "shallow",
                                              size: "64px"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.dialogServicesUpdateSelection
                                .dependentsDetails.direct.length ||
                              _vm.dialogServicesUpdateSelection
                                .dependentsDetails.indirect.length
                                ? [
                                    _c("q-item-separator"),
                                    _c(
                                      "q-list-header",
                                      {
                                        staticClass:
                                          "text-weight-bold text-grey text-left"
                                      },
                                      [_vm._v("Effect")]
                                    ),
                                    _vm.dialogServicesUpdateSelection
                                      .dependentsDetails.direct.length
                                      ? _c(
                                          "q-item",
                                          {
                                            attrs: { highlight: "", link: "" }
                                          },
                                          [
                                            _c(
                                              "q-item-side",
                                              { staticClass: "text-center" },
                                              [
                                                _c("q-icon", {
                                                  attrs: {
                                                    name: "ion-git-commit",
                                                    color: "purple-l2",
                                                    size: "20px"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-main",
                                              [
                                                _c(
                                                  "q-item-tile",
                                                  { attrs: { sublabel: "" } },
                                                  [
                                                    _vm._v(
                                                      "Impacts " +
                                                        _vm._s(
                                                          _vm
                                                            .dialogServicesUpdateSelection
                                                            .dependentsDetails
                                                            .direct.length
                                                        ) +
                                                        " service(s)"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.dialogServicesUpdateSelection
                                      .dependentsDetails.indirect.length
                                      ? _c(
                                          "q-item",
                                          {
                                            attrs: { highlight: "", link: "" }
                                          },
                                          [
                                            _c(
                                              "q-item-side",
                                              { staticClass: "text-center" },
                                              [
                                                _c("q-icon", {
                                                  attrs: {
                                                    name: "ion-git-network",
                                                    color: "purple-l2",
                                                    size: "20px"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "q-item-main",
                                              [
                                                _c(
                                                  "q-item-tile",
                                                  { attrs: { sublabel: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                Influences " +
                                                        _vm._s(
                                                          _vm
                                                            .dialogServicesUpdateSelection
                                                            .dependentsDetails
                                                            .indirect.length
                                                        ) +
                                                        " additional service(s)"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-grey text-left"
                                },
                                [
                                  _vm._v("Features\n            "),
                                  _vm.dialogServicesUpdateSelection.components
                                    .length
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.dialogServicesUpdateSelection
                                                .components.length
                                            ) +
                                            ")"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _vm._l(
                                _vm.dialogServicesUpdateSelection
                                  .componentsDetails,
                                function(comp) {
                                  return _c(
                                    "q-item",
                                    {
                                      key: "modify-comp-" + comp.uuid,
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.changeComponentStatus(comp)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "q-item-side",
                                        {
                                          staticClass: "text-center",
                                          nativeOn: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.removeComponentDialog(
                                                _vm.dialogServicesUpdateSelection,
                                                comp
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("q-icon", {
                                            attrs: {
                                              name: "ion-remove-circle-outline",
                                              color: "protect",
                                              size: "33px"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.dialogServicesUpdateSelection
                                        .components.length > 1
                                        ? _c(
                                            "q-item-side",
                                            {
                                              staticClass: "text-center",
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.serviceChangeIcon(
                                                    comp.uuid
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("q-icon", {
                                                attrs: {
                                                  name:
                                                    _vm
                                                      .dialogServicesUpdateSelection
                                                      .icon === comp.uuid
                                                      ? "ion-checkmark-circle-outline"
                                                      : "ion-radio-button-off",
                                                  size: "33px"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "q-item-side",
                                        { staticClass: "text-center" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/services/" +
                                                comp.categoryId +
                                                "." +
                                                comp.componentId +
                                                ".svg",
                                              width: "33"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "q-item-main",
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass: "capitalize",
                                              attrs: { label: "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getComponentName(comp)
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "text-fixedwidth-brand text-weight-bold text-subinfo-l",
                                              attrs: { sublabel: "" }
                                            },
                                            [
                                              _c("q-icon", {
                                                staticStyle: {
                                                  "margin-right": "-3px",
                                                  position: "relative",
                                                  top: "-1px"
                                                },
                                                attrs: {
                                                  name: "ion-pulse",
                                                  size: "20px"
                                                }
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("dtformat")(
                                                      comp.updated
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "text-fixedwidth-brand text-weight-bold text-subinfo-l ellipsis hidden",
                                              attrs: { sublabel: "" }
                                            },
                                            [
                                              _c("q-icon", {
                                                staticStyle: {
                                                  "margin-right": "-3px",
                                                  position: "relative",
                                                  top: "-1px"
                                                },
                                                attrs: {
                                                  name: "ion-at",
                                                  size: "20px"
                                                }
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(comp.uuid) +
                                                  "\n              "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        { attrs: { right: "" } },
                                        [
                                          comp.status === 0
                                            ? _c("q-icon", {
                                                attrs: {
                                                  name: "ion-checkmark-circle",
                                                  color: "educate",
                                                  size: "32px"
                                                }
                                              })
                                            : comp.status === 1
                                            ? _c("q-icon", {
                                                attrs: {
                                                  name: "ion-alert",
                                                  color: "attention",
                                                  size: "32px"
                                                }
                                              })
                                            : comp.status === 2
                                            ? _c("q-icon", {
                                                attrs: {
                                                  name: "ion-close-circle",
                                                  color: "protect",
                                                  size: "32px"
                                                }
                                              })
                                            : comp.status === 3
                                            ? _c("q-icon", {
                                                staticClass: "statusFader",
                                                attrs: {
                                                  name: "ion-code-working",
                                                  color: "purple-l2",
                                                  size: "32px"
                                                }
                                              })
                                            : _c("q-icon", {
                                                attrs: {
                                                  name: "ion-code",
                                                  color: "shallow",
                                                  size: "32px"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm.dialogServicesUpdateSelection.components
                                .length
                                ? _c("q-item-separator")
                                : _vm._e(),
                              _c(
                                "q-item",
                                {
                                  attrs: {
                                    link: "",
                                    tag: "label",
                                    disabled:
                                      _vm.dialogServicesUpdateSelection
                                        .components.length >=
                                      _vm.dialogServicesAddComponentsLimit
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.dialogComponentsAdd(true)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-add",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "primary",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "text-primary",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Add a Feature")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-grey text-left"
                                },
                                [
                                  _vm._v("Dependencies "),
                                  _vm.dialogServicesUpdateSelection
                                    .dependenciesDetails.dependencies.length
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.dialogServicesUpdateSelection
                                                .dependenciesDetails
                                                .dependencies.length
                                            ) +
                                            ")"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _vm._l(
                                _vm.dialogServicesUpdateSelection
                                  .dependenciesDetails.dependencies,
                                function(deps) {
                                  return _c(
                                    "q-item",
                                    {
                                      key: "modify-deps-" + deps,
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.componentUpdate(deps)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "q-item-side",
                                        {
                                          staticClass: "text-center",
                                          nativeOn: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.serviceRemoveDependencyDialog(
                                                deps
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("q-icon", {
                                            attrs: {
                                              name: "ion-remove-circle-outline",
                                              color: "protect",
                                              size: "33px"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        { staticClass: "text-center" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/services/" +
                                                _vm.computeServiceProperties(
                                                  deps
                                                ).iconDetails.categoryId +
                                                "." +
                                                _vm.computeServiceProperties(
                                                  deps
                                                ).iconDetails.componentId +
                                                ".svg",
                                              width: "33"
                                            }
                                          }),
                                          _c("q-icon", {
                                            staticClass: "relative-position",
                                            staticStyle: {
                                              top: "-25px",
                                              left: "-10px",
                                              "background-color": "#fff",
                                              "border-radius": "2em",
                                              width: "22px",
                                              height: "22px",
                                              "margin-right": "-16px"
                                            },
                                            attrs: {
                                              color: "purple-l2",
                                              name: "ion-radio-button-on",
                                              size: "20px"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-main",
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass: "capitalize",
                                              attrs: { label: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.computeServiceProperties(
                                                      deps
                                                    ).label
                                                  ) +
                                                  "\n                "
                                              ),
                                              !_vm.computeServiceProperties(
                                                deps
                                              ).label
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getServiceName(
                                                          _vm.computeServiceProperties(
                                                            deps
                                                          )
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "text-fixedwidth-brand",
                                              attrs: { sublabel: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getServiceCategoryLabel(
                                                      _vm.computeServiceProperties(
                                                        deps
                                                      ).iconDetails.categoryId
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        { attrs: { right: "" } },
                                        [
                                          _vm.serviceStatus(deps) === 0
                                            ? _c("q-icon", {
                                                attrs: {
                                                  name: "ion-checkmark-circle",
                                                  color: "educate",
                                                  size: "32px"
                                                }
                                              })
                                            : _vm.serviceStatus(deps) === 1
                                            ? _c("q-icon", {
                                                attrs: {
                                                  name: "ion-alert",
                                                  color: "attention",
                                                  size: "32px"
                                                }
                                              })
                                            : _vm.serviceStatus(deps) === 2
                                            ? _c("q-icon", {
                                                attrs: {
                                                  name: "ion-close-circle",
                                                  color: "protect",
                                                  size: "32px"
                                                }
                                              })
                                            : _vm.serviceStatus(deps) === 3
                                            ? _c("q-icon", {
                                                staticClass: "statusFader",
                                                attrs: {
                                                  name: "ion-code-working",
                                                  color: "purple-l2",
                                                  size: "32px"
                                                }
                                              })
                                            : _c("q-icon", {
                                                attrs: {
                                                  name: "ion-code",
                                                  color: "shallow",
                                                  size: "32px"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm.dialogServicesUpdateSelection
                                .dependenciesDetails.dependencies.length
                                ? _c("q-item-separator")
                                : _vm._e(),
                              _c(
                                "q-item",
                                {
                                  attrs: {
                                    link: "",
                                    tag: "label",
                                    disabled:
                                      _vm.dialogServicesUpdateSelection
                                        .dependenciesDetails.dependencies
                                        .length >=
                                      _vm.dialogServicesAddComponentsLimit
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.dialogDependencyAdd()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-add",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "primary",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "text-primary",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Link a Service")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-grey text-left"
                                },
                                [_vm._v("Settings")]
                              ),
                              _c(
                                "q-item",
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-qr-scanner",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "capitalize",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Icon")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [
                                          _vm.dialogServicesUpdateSelection
                                            .components.length === 1
                                            ? [_vm._v("Using feature icon")]
                                            : [
                                                _vm._v("Selected "),
                                                _c("q-icon", {
                                                  staticClass:
                                                    "text-weight-semibold",
                                                  attrs: {
                                                    name:
                                                      "ion-checkmark-circle-outline",
                                                    size: "18px"
                                                  }
                                                }),
                                                _vm._v(" from feature list")
                                              ]
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    { attrs: { right: "" } },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            mode: "out-in",
                                            appear: "",
                                            "enter-active-class":
                                              "animated400 bounceIn",
                                            "leave-active-class":
                                              "animated400 bounceOut"
                                          }
                                        },
                                        [
                                          _vm.dialogServicesUpdateSelection.icon
                                            ? _c("img", {
                                                key:
                                                  "services-icon-" +
                                                  _vm
                                                    .dialogServicesUpdateSelection
                                                    .icon,
                                                attrs: {
                                                  src:
                                                    "/statics/services/" +
                                                    _vm
                                                      .dialogServicesUpdateSelection
                                                      .iconDetails.categoryId +
                                                    "." +
                                                    _vm
                                                      .dialogServicesUpdateSelection
                                                      .iconDetails.componentId +
                                                    ".svg",
                                                  height: "32"
                                                }
                                              })
                                            : _c("q-icon", {
                                                key: "services-icon-off",
                                                attrs: {
                                                  name: "ion-qr-scanner",
                                                  color: _vm.anyDarkmode
                                                    ? ""
                                                    : "blue-grey-10",
                                                  size: "33px",
                                                  disabled: ""
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.serviceChangeLabel($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name:
                                            "ion-information-circle-outline",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v("Label")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [_vm._v("Visible to Public")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass:
                                        "text-weight-semibold text-black",
                                      attrs: { right: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.dialogServicesUpdateSelection
                                              .label
                                          ) +
                                          "\n              "
                                      ),
                                      !_vm.dialogServicesUpdateSelection.label
                                        ? _c(
                                            "span",
                                            { staticClass: "text-attention" },
                                            [_vm._v("Not Set")]
                                          )
                                        : _vm._e(),
                                      _c("img", {
                                        staticClass:
                                          "on-right-sm relative-position",
                                        staticStyle: { top: "4px" },
                                        attrs: {
                                          helper: "",
                                          src: "/statics/_demo/edit-text.svg",
                                          width: "22"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.serviceChangeDescription(
                                        $event
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-paper",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v("Description")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [_vm._v("Visible to Public")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass:
                                        "text-weight-semibold text-black",
                                      attrs: { right: "" }
                                    },
                                    [
                                      !_vm.dialogServicesUpdateSelection
                                        .description
                                        ? _c(
                                            "span",
                                            { staticClass: "text-attention" },
                                            [_vm._v("Not Set")]
                                          )
                                        : _c("q-icon", {
                                            attrs: {
                                              name: "ion-checkmark-circle",
                                              color: "educate",
                                              size: "33px"
                                            }
                                          }),
                                      _c("img", {
                                        staticClass:
                                          "on-right-sm relative-position",
                                        staticStyle: { top: "4px" },
                                        attrs: {
                                          helper: "",
                                          src: "/statics/_demo/edit-text.svg",
                                          width: "22"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.serviceChangeRefId($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-grid",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [
                                          _vm._v("ID "),
                                          _c(
                                            "q-chip",
                                            {
                                              attrs: { dense: "", denser: "" }
                                            },
                                            [_vm._v("Private")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [_vm._v("Internal reference")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass:
                                        "text-weight-semibold text-black",
                                      attrs: { right: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.dialogServicesUpdateSelection
                                              .refId
                                          ) +
                                          "\n              "
                                      ),
                                      !_vm.dialogServicesUpdateSelection.refId
                                        ? _c(
                                            "span",
                                            { staticClass: "text-attention" },
                                            [_vm._v("Not Set")]
                                          )
                                        : _vm._e(),
                                      _c("img", {
                                        staticClass:
                                          "on-right-sm relative-position",
                                        staticStyle: { top: "4px" },
                                        attrs: {
                                          helper: "",
                                          src: "/statics/_demo/edit-text.svg",
                                          width: "22"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-grey text-left"
                                },
                                [_vm._v("Display")]
                              ),
                              _c(
                                "q-item",
                                { attrs: { link: "", tag: "label" } },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            mode: "out-in",
                                            appear: "",
                                            "enter-active-class":
                                              "animated400 bounceIn",
                                            "leave-active-class":
                                              "animated400 bounceOut"
                                          }
                                        },
                                        [
                                          !_vm.dialogServicesUpdateSelection
                                            .private
                                            ? _c("q-icon", {
                                                key: "loyalty-enabled-on",
                                                attrs: {
                                                  name: "ion-eye",
                                                  color: _vm.anyDarkmode
                                                    ? ""
                                                    : "blue-grey-10",
                                                  size: "33px"
                                                }
                                              })
                                            : _c("q-icon", {
                                                key: "loyalty-enabled-off",
                                                attrs: {
                                                  name: "ion-eye-off",
                                                  color: _vm.anyDarkmode
                                                    ? ""
                                                    : "blue-grey-10",
                                                  size: "33px"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v("Hidden")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [
                                          _vm._v(
                                            "Not visible on public channel"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    { attrs: { right: "" } },
                                    [
                                      _c("q-toggle", {
                                        attrs: { dark: _vm.anyDarkmode },
                                        on: { input: _vm.serviceChangePrivacy },
                                        model: {
                                          value:
                                            _vm.dialogServicesUpdateSelection
                                              .private,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.dialogServicesUpdateSelection,
                                              "private",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogServicesUpdateSelection.private"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item",
                                { attrs: { item: "", link: "", tag: "label" } },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-pricetag",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [
                                          _vm._v("New \n                "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "q-chip q-chip-square q-chip-dense bg-blue text-white inline-block margin-xs-r"
                                            },
                                            [_vm._v("NEW")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [_vm._v('Add a "new" label')]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    { attrs: { right: "" } },
                                    [
                                      _c("q-toggle", {
                                        attrs: { dark: _vm.anyDarkmode },
                                        on: { input: _vm.serviceChangeIsNew },
                                        model: {
                                          value:
                                            _vm.dialogServicesUpdateSelection
                                              .isNew,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.dialogServicesUpdateSelection,
                                              "isNew",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogServicesUpdateSelection.isNew"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                { staticClass: "text-weight-bold text-left" },
                                [_vm._v("Cost")]
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { item: "", link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.serviceChangeCostValue($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-wallet",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v("Price")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [
                                          _vm._v(
                                            "Starting or final unit cost to fulfill"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass:
                                        "text-weight-semibold text-black",
                                      attrs: { right: "" }
                                    },
                                    [
                                      !_vm.dialogServicesUpdateSelection.cost &&
                                      _vm.dialogServicesUpdateSelection.cost !==
                                        0
                                        ? _c(
                                            "span",
                                            { staticClass: "text-attention" },
                                            [_vm._v("Not Set")]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("nformat")(
                                                  _vm
                                                    .dialogServicesUpdateSelection
                                                    .cost,
                                                  "0,0.00"
                                                )
                                              ) +
                                                " " +
                                                _vm._s(_vm.currency.label)
                                            )
                                          ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item",
                                { attrs: { item: "", link: "", tag: "label" } },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-pie",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v("Add-on")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [_vm._v("Not a standalone service")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    { attrs: { right: "" } },
                                    [
                                      _c("q-toggle", {
                                        attrs: { dark: _vm.anyDarkmode },
                                        on: { input: _vm.serviceChangePartial },
                                        model: {
                                          value:
                                            _vm.dialogServicesUpdateSelection
                                              .partial,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.dialogServicesUpdateSelection,
                                              "partial",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogServicesUpdateSelection.partial"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                { staticClass: "text-weight-bold text-left" },
                                [_vm._v("Requests")]
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: {
                                    item: "",
                                    link: "",
                                    tag: "label",
                                    disabled: ""
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-download",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [
                                          _vm._v(
                                            "\n                Requests\n                "
                                          ),
                                          _c(
                                            "q-chip",
                                            {
                                              attrs: { denser: "", dense: "" }
                                            },
                                            [_vm._v("Coming soon")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [
                                          _vm._v(
                                            "Customers can request this service"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    { attrs: { right: "" } },
                                    [
                                      _c("q-toggle", {
                                        attrs: { dark: _vm.anyDarkmode },
                                        on: {
                                          input: _vm.serviceChangeRequests
                                        },
                                        model: {
                                          value:
                                            _vm.dialogServicesUpdateSelection
                                              .requests,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.dialogServicesUpdateSelection,
                                              "requests",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dialogServicesUpdateSelection.requests"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: {
                                    item: "",
                                    disabled: !_vm.dialogServicesUpdateSelection
                                      .requests,
                                    link: "",
                                    tag: "label"
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-stopwatch",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [
                                          _vm._v(
                                            "\n                Fulfillment Time\n                "
                                          ),
                                          _c(
                                            "q-chip",
                                            {
                                              attrs: { denser: "", dense: "" }
                                            },
                                            [_vm._v("Coming soon")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [
                                          _vm._v(
                                            "\n                Time (in seconds) to process request\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass:
                                        "text-weight-semibold text-black",
                                      attrs: { right: "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-attention" },
                                        [_vm._v("Not Set")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: {
                                    item: "",
                                    disabled: !_vm.dialogServicesUpdateSelection
                                      .requests,
                                    link: "",
                                    tag: "label"
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-flask",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [
                                          _vm._v(
                                            "\n                Workflow\n                "
                                          ),
                                          _c(
                                            "q-chip",
                                            {
                                              attrs: { denser: "", dense: "" }
                                            },
                                            [_vm._v("Coming soon")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [
                                          _vm._v(
                                            "\n                Workflow to process request\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass:
                                        "text-weight-semibold text-black",
                                      attrs: { right: "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-attention" },
                                        [_vm._v("Not Set")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                { staticClass: "text-weight-bold text-left" },
                                [_vm._v("Widgets")]
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { item: "", link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.copyToClipboard(
                                        _vm.widgetSingleServiceWebLink(
                                          _vm.dialogServicesUpdateSelection.uuid
                                        ),
                                        $event
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-albums",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v("Status")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "text-fixedwidth-brand",
                                          attrs: { sublabel: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.widgetSingleServiceWebLink(
                                                  _vm
                                                    .dialogServicesUpdateSelection
                                                    .uuid
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("q-item-side", { attrs: { right: "" } }, [
                                    _c("img", {
                                      staticClass:
                                        "on-right-sm relative-position",
                                      staticStyle: { top: "4px" },
                                      attrs: {
                                        helper: "",
                                        src: "/statics/_demo/copy.svg",
                                        width: "22"
                                      }
                                    })
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                { staticClass: "text-weight-bold text-left" },
                                [_vm._v("UUIDs")]
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { item: "", link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.copyToClipboard(
                                        _vm.dialogServicesUpdateSelection.uuid,
                                        $event
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-at",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [
                                          _c(
                                            "q-chip",
                                            {
                                              attrs: { square: "", dense: "" }
                                            },
                                            [_vm._v("Service")]
                                          ),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm
                                                  .dialogServicesUpdateSelection
                                                  .label
                                              ) +
                                              "\n                "
                                          ),
                                          !_vm.dialogServicesUpdateSelection
                                            .label
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getServiceName(
                                                      _vm.dialogServicesUpdateSelection
                                                    ) || "No Label"
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "text-fixedwidth-brand",
                                          attrs: { sublabel: "" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dialogServicesUpdateSelection
                                                .uuid
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("q-item-side", { attrs: { right: "" } }, [
                                    _c("img", {
                                      staticClass:
                                        "on-right-sm relative-position",
                                      staticStyle: { top: "4px" },
                                      attrs: {
                                        helper: "",
                                        src: "/statics/_demo/copy.svg",
                                        width: "22"
                                      }
                                    })
                                  ])
                                ],
                                1
                              ),
                              _c("q-item-separator"),
                              _vm._l(
                                _vm.dialogServicesUpdateSelection
                                  .componentsDetails,
                                function(comp) {
                                  return _c(
                                    "q-item",
                                    {
                                      key: "dev-" + comp.uuid,
                                      attrs: {
                                        item: "",
                                        link: "",
                                        tag: "label"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.copyToClipboard(
                                            comp.uuid,
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "q-item-side",
                                        { staticClass: "text-center" },
                                        [
                                          _c("q-icon", {
                                            attrs: {
                                              name: "ion-at",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-main",
                                        [
                                          _c(
                                            "q-item-tile",
                                            { attrs: { label: "" } },
                                            [
                                              _c(
                                                "q-chip",
                                                {
                                                  attrs: {
                                                    square: "",
                                                    dense: ""
                                                  }
                                                },
                                                [_vm._v("Feature")]
                                              ),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getComponentName(comp)
                                                  ) +
                                                  "\n              "
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "text-fixedwidth-brand",
                                              attrs: { sublabel: "" }
                                            },
                                            [_vm._v(_vm._s(comp.uuid))]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-side",
                                        { attrs: { right: "" } },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "on-right-sm relative-position",
                                            staticStyle: { top: "4px" },
                                            attrs: {
                                              helper: "",
                                              src: "/statics/_demo/copy.svg",
                                              width: "22"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-protect text-left"
                                },
                                [_vm._v("Danger Zone")]
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.removeService(
                                        _vm.dialogServicesUpdateSelection.uuid
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-trash",
                                          color: "protect",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass:
                                            "capitalize text-protect",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Delete Service")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [_vm._v("Operation cannot be reversed")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-right"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.dialogServicesUpdateSelection.uuid)
                              ),
                              _c("br"),
                              _vm._v(
                                _vm._s(
                                  _vm._f("dt12format_EST")(
                                    _vm.dialogServicesUpdateSelection
                                      .statusDetails.updated
                                  )
                                ) + " (EST)"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item dialog-grow dialog-grow-full",
              attrs: { id: "dialogModuleGroupsAdd", position: "bottom" },
              model: {
                value: _vm.dialogServicesGroupsShow,
                callback: function($$v) {
                  _vm.dialogServicesGroupsShow = $$v
                },
                expression: "dialogServicesGroupsShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _vm.dialogServicesGroupsSelectionMode
                        ? _c("q-toolbar-title", [_vm._v("select group")])
                        : _c("q-toolbar-title", [_vm._v("manage groups")])
                    ],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "empower-light",
                                    "text-color": "empower",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.soundPlay("sheet_up")
                                      _vm.groupCreate()
                                    }
                                  }
                                },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-add-circle",
                                      size: "28px"
                                    }
                                  }),
                                  _vm._v("   Create\n            ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.dialogGroupsHide($event)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      !_vm.product.data.business.groups.order.length
                        ? _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "" }
                            },
                            [
                              _c(
                                "q-item",
                                [
                                  _c(
                                    "q-item-main",
                                    { staticClass: "text-center text-grey" },
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [
                                          _vm._v("No groups create. Tap "),
                                          _c("q-icon", {
                                            attrs: {
                                              name: "ion-add-circle",
                                              size: "22px"
                                            }
                                          }),
                                          _vm._v(
                                            " to create a group of services."
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    list: _vm.product.data.business.groups.order
                                  },
                                  on: {
                                    end: function($event) {
                                      return _vm.groupReorderGroups()
                                    }
                                  }
                                },
                                _vm._l(
                                  _vm.product.data.business.groups.order,
                                  function(groupId, groupIndex) {
                                    return _c(
                                      "q-item",
                                      {
                                        key: "group-guid-" + groupId,
                                        attrs: { link: "", tag: "label" },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.dialogGroupShow(groupId)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "q-item-side",
                                          { staticClass: "text-center" },
                                          [
                                            _c("q-icon", {
                                              attrs: {
                                                name: "ion-folder-open",
                                                size: "33px"
                                              }
                                            }),
                                            _vm.product.data.business.groups
                                              .list[groupId].private
                                              ? _c("q-icon", {
                                                  staticClass: "absolute",
                                                  staticStyle: {
                                                    top: "8px",
                                                    left: "28px",
                                                    height: "20px",
                                                    border: "4px solid #fff",
                                                    background: "#fff",
                                                    "border-radius": "2em"
                                                  },
                                                  attrs: {
                                                    color: "protect",
                                                    name: "ion-eye-off",
                                                    size: "20px"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-main",
                                          [
                                            _c(
                                              "q-item-tile",
                                              { attrs: { label: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.product.data.business
                                                      .groups.list[groupId]
                                                      .title
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "q-item-tile",
                                              { attrs: { sublabel: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.product.data.business
                                                      .groups.list[groupId]
                                                      .description
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.product.data.business.groups.list[
                                          groupId
                                        ].services &&
                                        _vm.product.data.business.groups.list[
                                          groupId
                                        ].services.length > 0
                                          ? _c(
                                              "q-item-side",
                                              [
                                                _c("q-chip", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.product.data.business
                                                        .groups.list[groupId]
                                                        .services &&
                                                        _vm.product.data
                                                          .business.groups.list[
                                                          groupId
                                                        ].services.length
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.dialogServicesGroupsSelectionMode
                                          ? _c(
                                              "q-item-side",
                                              { attrs: { right: "" } },
                                              [
                                                _c("q-icon", {
                                                  staticStyle: {
                                                    cursor: "move"
                                                  },
                                                  attrs: {
                                                    name: "ion-reorder",
                                                    size: "33px"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogModuleGroupSettings", position: "bottom" },
              model: {
                value: _vm.dialogServicesGroupShow,
                callback: function($$v) {
                  _vm.dialogServicesGroupShow = $$v
                },
                expression: "dialogServicesGroupShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("manage group")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.dialogGroupHide($event)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.dialogServicesGroupId &&
                  _vm.product.data.business.groups.list[
                    _vm.dialogServicesGroupId
                  ]
                    ? _c(
                        "div",
                        { staticClass: "layout-padding no-padding-top" },
                        [
                          _c("q-scroll-observable", {
                            on: { scroll: _vm.toolbarShadowOnOverscroll }
                          }),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-grey text-left"
                                },
                                [_vm._v("Settings")]
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.groupChangeName($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name:
                                            "ion-information-circle-outline",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "capitalize",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Name")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [_vm._v("Group title")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass:
                                        "text-weight-semibold text-black",
                                      attrs: { right: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.product.data.business.groups
                                              .list[_vm.dialogServicesGroupId]
                                              .title
                                          ) +
                                          "\n              "
                                      ),
                                      _c("img", {
                                        staticClass:
                                          "on-right-sm relative-position",
                                        staticStyle: { top: "4px" },
                                        attrs: {
                                          helper: "",
                                          src: "/statics/_demo/edit-text.svg",
                                          width: "22"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.groupChangeDescription($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name:
                                            "ion-information-circle-outline",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "blue-grey-10",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "capitalize",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Description")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [
                                          _vm._v(
                                            "Describe what defines your group"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass:
                                        "text-weight-semibold text-black",
                                      attrs: { right: "" }
                                    },
                                    [
                                      !_vm.product.data.business.groups.list[
                                        _vm.dialogServicesGroupId
                                      ].description
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-attention text-weight-semibold"
                                            },
                                            [_vm._v("Not Set")]
                                          )
                                        : _c("q-icon", {
                                            attrs: {
                                              name: "ion-checkmark-circle",
                                              color: "educate",
                                              size: "33px"
                                            }
                                          }),
                                      _c("img", {
                                        staticClass:
                                          "on-right-sm relative-position",
                                        staticStyle: { top: "4px" },
                                        attrs: {
                                          helper: "",
                                          src: "/statics/_demo/edit-text.svg",
                                          width: "22"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-grey text-left"
                                },
                                [_vm._v("Display")]
                              ),
                              _c(
                                "q-item",
                                { attrs: { link: "", tag: "label" } },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            mode: "out-in",
                                            appear: "",
                                            "enter-active-class":
                                              "animated400 bounceIn",
                                            "leave-active-class":
                                              "animated400 bounceOut"
                                          }
                                        },
                                        [
                                          !_vm.product.data.business.groups
                                            .list[_vm.dialogServicesGroupId]
                                            .private
                                            ? _c("q-icon", {
                                                key: "loyalty-enabled-on",
                                                attrs: {
                                                  name: "ion-eye",
                                                  color: _vm.anyDarkmode
                                                    ? ""
                                                    : "blue-grey-10",
                                                  size: "33px"
                                                }
                                              })
                                            : _c("q-icon", {
                                                key: "loyalty-enabled-off",
                                                attrs: {
                                                  name: "ion-eye-off",
                                                  color: _vm.anyDarkmode
                                                    ? ""
                                                    : "blue-grey-10",
                                                  size: "33px"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v("Hidden")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [
                                          _vm._v(
                                            "Not visible on public channel"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    { attrs: { right: "" } },
                                    [
                                      _c("q-toggle", {
                                        attrs: { dark: _vm.anyDarkmode },
                                        on: { input: _vm.groupChangePrivacy },
                                        model: {
                                          value:
                                            _vm.product.data.business.groups
                                              .list[_vm.dialogServicesGroupId]
                                              .private,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.product.data.business.groups
                                                .list[
                                                _vm.dialogServicesGroupId
                                              ],
                                              "private",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "product.data.business.groups.list[dialogServicesGroupId].private"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-grey text-left"
                                },
                                [_vm._v("Services")]
                              ),
                              _c(
                                "draggable",
                                {
                                  attrs: {
                                    list:
                                      _vm.product.data.business.groups.list[
                                        _vm.dialogServicesGroupId
                                      ].services
                                  },
                                  on: {
                                    end: function($event) {
                                      return _vm.groupReorderGroups()
                                    }
                                  }
                                },
                                _vm._l(
                                  _vm.product.data.business.groups.list[
                                    _vm.dialogServicesGroupId
                                  ].services,
                                  function(deps) {
                                    return _c(
                                      "q-item",
                                      {
                                        key: "modify-deps-" + deps,
                                        attrs: { link: "" }
                                      },
                                      [
                                        _c(
                                          "q-item-side",
                                          {
                                            staticClass: "text-center",
                                            nativeOn: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.groupRemoveService(
                                                  deps
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("q-icon", {
                                              attrs: {
                                                name:
                                                  "ion-remove-circle-outline",
                                                color: "protect",
                                                size: "33px"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-side",
                                          { staticClass: "text-center" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  "/statics/services/" +
                                                  _vm.computeServiceProperties(
                                                    deps
                                                  ).iconDetails.categoryId +
                                                  "." +
                                                  _vm.computeServiceProperties(
                                                    deps
                                                  ).iconDetails.componentId +
                                                  ".svg",
                                                width: "33"
                                              }
                                            }),
                                            _c("q-icon", {
                                              staticClass: "relative-position",
                                              staticStyle: {
                                                top: "-25px",
                                                left: "-10px",
                                                "background-color": "#fff",
                                                "border-radius": "2em",
                                                width: "22px",
                                                height: "22px",
                                                "margin-right": "-16px"
                                              },
                                              attrs: {
                                                color: "purple-l2",
                                                name: "ion-folder-open",
                                                size: "20px"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-main",
                                          [
                                            _c(
                                              "q-item-tile",
                                              {
                                                staticClass: "capitalize",
                                                attrs: { label: "" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.computeServiceProperties(
                                                        deps
                                                      ).label
                                                    ) +
                                                    "\n                "
                                                ),
                                                !_vm.computeServiceProperties(
                                                  deps
                                                ).label
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getServiceName(
                                                            _vm.computeServiceProperties(
                                                              deps
                                                            )
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c(
                                              "q-item-tile",
                                              {
                                                staticClass:
                                                  "text-fixedwidth-brand",
                                                attrs: { sublabel: "" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.getServiceCategoryLabel(
                                                        _vm.computeServiceProperties(
                                                          deps
                                                        ).iconDetails.categoryId
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-side",
                                          { attrs: { right: "" } },
                                          [
                                            _vm.serviceStatus(deps) === 0
                                              ? _c("q-icon", {
                                                  attrs: {
                                                    name:
                                                      "ion-checkmark-circle",
                                                    color: "educate",
                                                    size: "32px"
                                                  }
                                                })
                                              : _vm.serviceStatus(deps) === 1
                                              ? _c("q-icon", {
                                                  attrs: {
                                                    name: "ion-alert",
                                                    color: "attention",
                                                    size: "32px"
                                                  }
                                                })
                                              : _vm.serviceStatus(deps) === 2
                                              ? _c("q-icon", {
                                                  attrs: {
                                                    name: "ion-close-circle",
                                                    color: "protect",
                                                    size: "32px"
                                                  }
                                                })
                                              : _vm.serviceStatus(deps) === 3
                                              ? _c("q-icon", {
                                                  staticClass: "statusFader",
                                                  attrs: {
                                                    name: "ion-code-working",
                                                    color: "purple-l2",
                                                    size: "32px"
                                                  }
                                                })
                                              : _c("q-icon", {
                                                  attrs: {
                                                    name: "ion-code",
                                                    color: "shallow",
                                                    size: "32px"
                                                  }
                                                })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-side",
                                          { attrs: { right: "" } },
                                          [
                                            _c("q-icon", {
                                              staticStyle: { cursor: "move" },
                                              attrs: {
                                                name: "ion-reorder",
                                                size: "33px"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _vm.product.data.business.groups.list[
                                _vm.dialogServicesGroupId
                              ].services &&
                              _vm.product.data.business.groups.list[
                                _vm.dialogServicesGroupId
                              ].services.length
                                ? _c("q-item-separator")
                                : _vm._e(),
                              _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.groupAddService()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-add",
                                          color: _vm.anyDarkmode
                                            ? ""
                                            : "primary",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass: "text-primary",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Add a Service")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-list",
                            {
                              staticClass:
                                "card text-system-brand text-weight-medium full-width on-top-lg",
                              attrs: { "no-border": "", link: "" }
                            },
                            [
                              _c(
                                "q-list-header",
                                {
                                  staticClass:
                                    "text-weight-bold text-protect text-left"
                                },
                                [_vm._v("Danger Zone")]
                              ),
                              _c(
                                "q-item",
                                {
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.groupRemoveDialog(
                                        _vm.dialogServicesGroupId
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name: "ion-trash",
                                          color: "protect",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        {
                                          staticClass:
                                            "capitalize text-protect",
                                          attrs: { label: "" }
                                        },
                                        [_vm._v("Delete Group")]
                                      ),
                                      _c(
                                        "q-item-tile",
                                        { attrs: { sublabel: "" } },
                                        [_vm._v("Operation cannot be reversed")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogModuleServicesAdd", position: "bottom" },
              model: {
                value: _vm.dialogServicesAddShow,
                callback: function($$v) {
                  _vm.dialogServicesAddShow = $$v
                },
                expression: "dialogServicesAddShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("create service station")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    loading: _vm.dialogServicesAddCreating,
                                    disabled: !(
                                      _vm.dialogServicesAddComponents.length &&
                                      _vm.dialogServicesAddLabel.length
                                    ),
                                    color: "empower-light",
                                    "text-color": "empower",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.soundPlay("sheet_up")
                                      _vm.serviceCreate()
                                    }
                                  }
                                },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-add-circle",
                                      size: "28px"
                                    }
                                  }),
                                  _vm._v("   Create\n            ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.dialogServicesAdd($event)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: {
                            "no-border": "",
                            link: "",
                            disable: _vm.dialogServicesAddCreating
                          }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [
                              _vm._v(
                                "Features (" +
                                  _vm._s(
                                    _vm.dialogServicesAddComponents.length
                                  ) +
                                  " / " +
                                  _vm._s(_vm.dialogServicesAddComponentsLimit) +
                                  ")"
                              )
                            ]
                          ),
                          _c(
                            "transition-group",
                            {
                              attrs: {
                                appear: "",
                                "enter-active-class": "animated400 fadeIn",
                                "leave-active-class": "animated400 fadeOut"
                              }
                            },
                            _vm._l(_vm.dialogServicesAddComponents, function(
                              component,
                              componentIndex
                            ) {
                              return _c(
                                "q-item",
                                {
                                  key:
                                    "component-guid-" +
                                    component.componentListId,
                                  attrs: { link: "", tag: "label" }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass: "text-center",
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialogServicesAddComponentsIconIndex = componentIndex
                                        }
                                      }
                                    },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          name:
                                            _vm.dialogServicesAddComponentsIconIndex ===
                                            componentIndex
                                              ? "ion-checkmark-circle-outline"
                                              : "ion-radio-button-off",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/statics/services/" +
                                            component.categoryId +
                                            "." +
                                            component.componentId +
                                            ".svg",
                                          height: "32"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.Wings.services.list[
                                                component.categoryId
                                              ][component.componentId].name ||
                                                _vm.Wings.services.list[
                                                  component.categoryId
                                                ][component.componentId].id
                                            )
                                          )
                                        ]
                                      ),
                                      _vm.Wings.services.list[
                                        component.categoryId
                                      ][component.componentId].description
                                        ? _c(
                                            "q-item-tile",
                                            { attrs: { sublabel: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.Wings.services.list[
                                                    component.categoryId
                                                  ][component.componentId]
                                                    .description
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-side",
                                    {
                                      staticClass: "text-center",
                                      attrs: { right: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.componentRemove(
                                            component.componentListId
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("q-icon", {
                                        attrs: {
                                          color: "protect",
                                          name: "ion-trash",
                                          size: "33px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm.dialogServicesAddComponents.length
                            ? _c("q-item-separator")
                            : _vm._e(),
                          _c(
                            "q-item",
                            {
                              attrs: {
                                link: "",
                                tag: "label",
                                disabled:
                                  _vm.dialogServicesAddComponents.length >=
                                    _vm.dialogServicesAddComponentsLimit ||
                                  _vm.dialogServicesAddCreating,
                                disable: _vm.dialogServicesAddCreating
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.dialogComponentsAdd(false)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-add",
                                      color: _vm.anyDarkmode ? "" : "primary",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "text-primary",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Add a Feature")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: {
                            "no-border": "",
                            disabled: _vm.dialogServicesAddCreating
                          }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Details")]
                          ),
                          _c(
                            "q-item",
                            { attrs: { link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-information-circle-outline",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Label")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Visible to all")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-input", {
                                    staticClass:
                                      "text-weight-semibold text-black",
                                    staticStyle: { width: "180px" },
                                    attrs: {
                                      disable: _vm.dialogServicesAddCreating,
                                      autofocus: "",
                                      placeholder: "eg. Barista"
                                    },
                                    model: {
                                      value: _vm.dialogServicesAddLabel,
                                      callback: function($$v) {
                                        _vm.dialogServicesAddLabel =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "dialogServicesAddLabel"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            { attrs: { link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-grid",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c("q-item-tile", { attrs: { label: "" } }, [
                                    _vm._v("ID")
                                  ]),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Visible to team")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-input", {
                                    staticClass:
                                      "text-weight-semibold text-black",
                                    staticStyle: { width: "180px" },
                                    attrs: {
                                      disable: _vm.dialogServicesAddCreating,
                                      "upper-case": "",
                                      placeholder: "eg. B02A, #23"
                                    },
                                    model: {
                                      value: _vm.dialogServicesAddId,
                                      callback: function($$v) {
                                        _vm.dialogServicesAddId =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "dialogServicesAddId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        mode: "out-in",
                                        appear: "",
                                        "enter-active-class":
                                          "animated400 bounceIn",
                                        "leave-active-class":
                                          "animated400 bounceOut"
                                      }
                                    },
                                    [
                                      _vm.dialogServicesAddComponents.length
                                        ? _c("img", {
                                            key:
                                              "services-icon-" +
                                              _vm.dialogServicesAddComponents[
                                                _vm
                                                  .dialogServicesAddComponentsIconIndex
                                              ].componentListId,
                                            attrs: {
                                              src:
                                                "/statics/services/" +
                                                _vm.dialogServicesAddComponents[
                                                  _vm
                                                    .dialogServicesAddComponentsIconIndex
                                                ].categoryId +
                                                "." +
                                                _vm.dialogServicesAddComponents[
                                                  _vm
                                                    .dialogServicesAddComponentsIconIndex
                                                ].componentId +
                                                ".svg",
                                              height: "32"
                                            }
                                          })
                                        : _c("q-icon", {
                                            key: "services-icon-off",
                                            attrs: {
                                              name: "ion-qr-scanner",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px",
                                              disabled: ""
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Icon")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Select from feature list")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _vm.dialogServicesAddComponents.length
                                    ? _c("q-icon", {
                                        attrs: {
                                          name: "ion-checkmark-circle",
                                          color: "educate",
                                          size: "33px"
                                        }
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-weight-semibold text-attention"
                                        },
                                        [_vm._v("Not Set")]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Support")]
                          ),
                          _c(
                            "q-item",
                            { attrs: { link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-collapsible",
                                    {
                                      attrs: {
                                        icon: "ion-apps",
                                        label: "What's a station?"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-weight-medium text-emphasis"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Create a station to group related features into a combined service, where the overall availability is determined by the status of all included features. Ideal for interconnected services where each feature's status impacts the entire offering, unlike stand-alone, independent feature-based services.\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogServicesSelector", position: "bottom" },
              model: {
                value: _vm.dialogServicesSelectorShow,
                callback: function($$v) {
                  _vm.dialogServicesSelectorShow = $$v
                },
                expression: "dialogServicesSelectorShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("select a service")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialogServicesSelectorShow = false
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _vm.dialogServicesSelectorList.length
                        ? [
                            _vm.dialogServicesUpdateSelection
                              ? _c("p", [
                                  _c(
                                    "span",
                                    { staticClass: "text-weight-semibold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getServiceName(
                                            _vm.dialogServicesUpdateSelection
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    " can depend on the following services:"
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "q-list",
                              {
                                staticClass:
                                  "card text-system-brand text-weight-medium full-width on-top-lg",
                                attrs: { "no-border": "", link: "" }
                              },
                              _vm._l(_vm.dialogServicesSelectorList, function(
                                service
                              ) {
                                return _c(
                                  "q-item",
                                  {
                                    key: "service-selector-" + service,
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.dialogDependencySelect(
                                          service
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "q-item-side",
                                      { staticClass: "text-center" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              "/statics/services/" +
                                              _vm.computeServiceProperties(
                                                service
                                              ).iconDetails.categoryId +
                                              "." +
                                              _vm.computeServiceProperties(
                                                service
                                              ).iconDetails.componentId +
                                              ".svg",
                                            width: "33"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "q-item-main",
                                      [
                                        _c(
                                          "q-item-tile",
                                          {
                                            staticClass: "capitalize",
                                            attrs: { label: "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.computeServiceProperties(
                                                    service
                                                  ).label
                                                ) +
                                                "\n                  "
                                            ),
                                            !_vm.computeServiceProperties(
                                              service
                                            ).label
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getServiceName(
                                                        _vm.computeServiceProperties(
                                                          service
                                                        )
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        ),
                                        _c(
                                          "q-item-tile",
                                          {
                                            staticClass:
                                              "text-fixedwidth-brand",
                                            attrs: { sublabel: "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.getServiceCategoryLabel(
                                                    _vm.computeServiceProperties(
                                                      service
                                                    ).iconDetails.categoryId
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item-side",
                                      { attrs: { right: "" } },
                                      [
                                        _vm.serviceStatus(service) === 0
                                          ? _c("q-icon", {
                                              attrs: {
                                                name: "ion-checkmark-circle",
                                                color: "educate",
                                                size: "32px"
                                              }
                                            })
                                          : _vm.serviceStatus(service) === 1
                                          ? _c("q-icon", {
                                              attrs: {
                                                name: "ion-alert",
                                                color: "attention",
                                                size: "32px"
                                              }
                                            })
                                          : _vm.serviceStatus(service) === 2
                                          ? _c("q-icon", {
                                              attrs: {
                                                name: "ion-close-circle",
                                                color: "protect",
                                                size: "32px"
                                              }
                                            })
                                          : _vm.serviceStatus(service) === 3
                                          ? _c("q-icon", {
                                              staticClass: "statusFader",
                                              attrs: {
                                                name: "ion-code-working",
                                                color: "purple-l2",
                                                size: "32px"
                                              }
                                            })
                                          : _c("q-icon", {
                                              attrs: {
                                                name: "ion-code",
                                                color: "shallow",
                                                size: "32px"
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ]
                        : [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-center text-shallow text-weight-semibold"
                              },
                              [_vm._v("No available services")]
                            )
                          ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogModuleComponentsAdd", position: "bottom" },
              model: {
                value: _vm.dialogComponentsAddShow,
                callback: function($$v) {
                  _vm.dialogComponentsAddShow = $$v
                },
                expression: "dialogComponentsAddShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("select feature")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.dialogComponentsAdd($event)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _vm._l(_vm.dialogServicesAddCategories, function(cat) {
                        return _c(
                          "q-list",
                          {
                            key: cat.value,
                            staticClass:
                              "card text-system-brand text-weight-medium full-width on-top-lg",
                            attrs: { "no-border": "", link: "" }
                          },
                          [
                            _c(
                              "q-list-header",
                              {
                                staticClass:
                                  "text-weight-bold text-grey text-left"
                              },
                              [_vm._v(_vm._s(cat.label))]
                            ),
                            _vm._l(_vm.Wings.services.list[cat.value], function(
                              comp
                            ) {
                              return _c(
                                "q-item",
                                {
                                  key: comp.id,
                                  attrs: { link: "", tag: "label" },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialogComponentsAdd()
                                      _vm.componentAdd(comp.id, cat.value)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/statics/services/" +
                                            cat.value +
                                            "." +
                                            comp.id +
                                            ".svg",
                                          height: "32"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "q-item-main",
                                    [
                                      _c(
                                        "q-item-tile",
                                        { attrs: { label: "" } },
                                        [_vm._v(_vm._s(comp.name || comp.id))]
                                      ),
                                      comp.description
                                        ? _c(
                                            "q-item-tile",
                                            { attrs: { sublabel: "" } },
                                            [_vm._v(_vm._s(comp.description))]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogModuleSettingsToday", position: "bottom" },
              model: {
                value: _vm.dialogModuleSettingsTodayShow,
                callback: function($$v) {
                  _vm.dialogModuleSettingsTodayShow = $$v
                },
                expression: "dialogModuleSettingsTodayShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("today")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.moduleSettingsToday($event)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Operations")]
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { item: "", link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.updateStatusChange()
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-warning",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c("q-item-tile", { attrs: { label: "" } }, [
                                    _vm._v("Announce Change")
                                  ]),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Notify change of operations")]
                                  )
                                ],
                                1
                              ),
                              _c("q-item-side", { attrs: { right: "" } }, [
                                _vm.channel_orders === 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-weight-semibold text-educate"
                                      },
                                      [_vm._v("No Change")]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-weight-semibold text-protect"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              this.getStatusChangeData(
                                                this.channel_orders
                                              ).label
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                              ]),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  this.channel_orders === 0
                                    ? _c("q-icon", {
                                        attrs: {
                                          name: "ion-checkmark-circle",
                                          color: "educate",
                                          size: "33px"
                                        }
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: this.getStatusChangeData(
                                            this.channel_orders
                                          ).icon,
                                          width: "33"
                                        }
                                      })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { item: "", link: "" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.triggerDialogHOO()
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-time",
                                      size: "33px",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Hours")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.today_current_time) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { sublabel: "" }
                                    },
                                    [_vm._v(_vm._s(_vm.today_day))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "block text-weight-semibold text-black"
                                    },
                                    [
                                      _vm.today_day
                                        ? [
                                            _vm.product.data.business.hoo[
                                              _vm.today_day
                                            ].is24
                                              ? [_vm._v("24 Hours")]
                                              : _vm.product.data.business.hoo[
                                                  _vm.today_day
                                                ].isClosed
                                              ? [_vm._v("Closed")]
                                              : [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f("t12format")(
                                                          _vm.product.data
                                                            .business.hoo[
                                                            _vm.today_day
                                                          ].open
                                                        )
                                                      ) +
                                                      "-" +
                                                      _vm._s(
                                                        _vm._f("t12format")(
                                                          _vm.product.data
                                                            .business.hoo[
                                                            _vm.today_day
                                                          ].close
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Channel")]
                          ),
                          _c(
                            "q-item",
                            { attrs: { item: "", link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        mode: "out-in",
                                        appear: "",
                                        "enter-active-class":
                                          "animated400 bounceIn",
                                        "leave-active-class":
                                          "animated400 bounceOut"
                                      }
                                    },
                                    [
                                      _vm.channel_online
                                        ? _c("q-icon", {
                                            key: "channel-online-on",
                                            staticClass: "pulse",
                                            attrs: {
                                              name: "ion-radio",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                        : _c("q-icon", {
                                            key: "channel-online-off",
                                            attrs: {
                                              name: "ion-radio-button-on",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [
                                      _vm.channel_online
                                        ? [_vm._v("Live")]
                                        : [_vm._v("Offline")]
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-toggle", {
                                    attrs: { dark: _vm.anyDarkmode },
                                    model: {
                                      value: _vm.channel_online,
                                      callback: function($$v) {
                                        _vm.channel_online = $$v
                                      },
                                      expression: "channel_online"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogModuleSettingsLoyalty", position: "bottom" },
              model: {
                value: _vm.dialogModuleSettingsLoyaltyShow,
                callback: function($$v) {
                  _vm.dialogModuleSettingsLoyaltyShow = $$v
                },
                expression: "dialogModuleSettingsLoyaltyShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("loyalty")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.moduleSettingsLoyalty($event)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Statistics")]
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { item: "", link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.moduleStatsLoyaltyToday()
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-clock",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c("q-item-tile", { attrs: { label: "" } }, [
                                    _vm._v("Today")
                                  ]),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("View today's rewards")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Appearance")]
                          ),
                          _c(
                            "q-item",
                            { attrs: { item: "", link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        mode: "out-in",
                                        appear: "",
                                        "enter-active-class":
                                          "animated400 bounceIn",
                                        "leave-active-class":
                                          "animated400 bounceOut"
                                      }
                                    },
                                    [
                                      _vm.loyalty_enabled
                                        ? _c("q-icon", {
                                            key: "loyalty-enabled-on",
                                            attrs: {
                                              name: "ion-eye",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                        : _c("q-icon", {
                                            key: "loyalty-enabled-off",
                                            attrs: {
                                              name: "ion-eye-off",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Offer Loyalty")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _vm._v(
                                        "Digital loyalty offering to subscribers"
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-toggle", {
                                    attrs: { dark: _vm.anyDarkmode },
                                    model: {
                                      value: _vm.loyalty_enabled,
                                      callback: function($$v) {
                                        _vm.loyalty_enabled = $$v
                                      },
                                      expression: "loyalty_enabled"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { item: "", link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.moduleSettingsLoyaltyStamps()
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-done-all",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Stamps")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _vm._v(
                                        "Transactions required to recieve reward"
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                {
                                  staticClass: "text-black",
                                  attrs: { right: "" }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        this.product.data.business.loyalty
                                          .stamps
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: {
                                item: "",
                                link: "",
                                tag: "label",
                                disabled: ""
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-gift",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Reward")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Reward type given")]
                                  )
                                ],
                                1
                              ),
                              _c("q-item-side", { attrs: { right: "" } }, [
                                !this.product.data.business.loyalty
                                  .rewardsMetaOptions.length
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                Free Item\n              "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                Multiple Types\n              "
                                      )
                                    ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: {
                                item: "",
                                link: "",
                                tag: "label",
                                disabled: ""
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-image",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Image")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Loyalty Card Photo")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-checkmark-circle",
                                      color: "educate",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogSettings", position: "bottom" },
              model: {
                value: _vm.dialogSettingsShow,
                callback: function($$v) {
                  _vm.dialogSettingsShow = $$v
                },
                expression: "dialogSettingsShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("channel")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "empower-light",
                                    "text-color": "empower",
                                    rounded: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openURL(_vm.productFullURI)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("PREVIEW")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialogSettingsShow = false
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Channel")]
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.openURL(_vm.productFullURI)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-radio",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Channel Page")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Open channel web link")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-icon", {
                                    attrs: { name: "ion-open", size: "33px" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.qrcode($event)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-qr-scanner",
                                      color: _vm.anyDarkmode
                                        ? "white"
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Channel QR Code")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("View / Download QR Code")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _vm.product.data.qrcode_ref
                                    ? _c("q-icon", {
                                        attrs: {
                                          name: "ion-checkmark-circle",
                                          color: "educate",
                                          size: "33px"
                                        }
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-weight-semibold text-attention"
                                        },
                                        [_vm._v("Not Set")]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Media")]
                          ),
                          _c(
                            "q-item",
                            { attrs: { link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-flower",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Logo")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Brand icon")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _vm.product.data.media.logo &&
                                  _vm.product.data.media.logo.url
                                    ? _c("q-icon", {
                                        attrs: {
                                          name: "ion-checkmark-circle",
                                          color: "educate",
                                          size: "33px"
                                        }
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-weight-semibold text-attention"
                                        },
                                        [_vm._v("Not Set")]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            { attrs: { link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-images",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Gallery")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Cover gallery photos")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _vm.product.data.media.photos &&
                                  _vm.product.data.media.photos.resources
                                    ? [
                                        _c("q-chip", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.product.data.media.photos
                                                .resources.length
                                            )
                                          )
                                        ]),
                                        _c("q-icon", {
                                          staticClass: "margin-xs-l",
                                          attrs: {
                                            name: "ion-checkmark-circle",
                                            color: "educate",
                                            size: "33px"
                                          }
                                        })
                                      ]
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-attention text-weight-semibold"
                                        },
                                        [_vm._v("Not Set")]
                                      )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("About")]
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.channelChangeName($event)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-information-circle-outline",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Name")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Channel title")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _vm.product.data.business.name
                                    ? _c("q-icon", {
                                        attrs: {
                                          name: "ion-checkmark-circle",
                                          color: "educate",
                                          size: "33px"
                                        }
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-attention text-weight-semibold"
                                        },
                                        [_vm._v("Not Set")]
                                      ),
                                  _c("img", {
                                    staticClass:
                                      "on-right-sm relative-position",
                                    staticStyle: { top: "4px" },
                                    attrs: {
                                      helper: "",
                                      src: "/statics/_demo/edit-text.svg",
                                      width: "22"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.channelChangeDescription($event)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-information-circle-outline",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Description")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("About your services")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _vm.product.data.business.metas.description
                                    ? _c("q-icon", {
                                        attrs: {
                                          name: "ion-checkmark-circle",
                                          color: "educate",
                                          size: "33px"
                                        }
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-attention text-weight-semibold"
                                        },
                                        [_vm._v("Not Set")]
                                      ),
                                  _c("img", {
                                    staticClass:
                                      "on-right-sm relative-position",
                                    staticStyle: { top: "4px" },
                                    attrs: {
                                      helper: "",
                                      src: "/statics/_demo/edit-text.svg",
                                      width: "22"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.channelChangeWebsite($event)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-link",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Website")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Marketing site")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _vm.product.data.business.website
                                    ? _c("q-icon", {
                                        attrs: {
                                          name: "ion-checkmark-circle",
                                          color: "educate",
                                          size: "33px"
                                        }
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-attention text-weight-semibold"
                                        },
                                        [_vm._v("Not Set")]
                                      ),
                                  _c("img", {
                                    staticClass:
                                      "on-right-sm relative-position",
                                    staticStyle: { top: "4px" },
                                    attrs: {
                                      helper: "",
                                      src: "/statics/_demo/edit-text.svg",
                                      width: "22"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.channelChangePhone($event)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-call",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Phone")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Main contact")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _vm.product.data.business.phone
                                    ? _c("q-icon", {
                                        attrs: {
                                          name: "ion-checkmark-circle",
                                          color: "educate",
                                          size: "33px"
                                        }
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-attention text-weight-semibold"
                                        },
                                        [_vm._v("Not Set")]
                                      ),
                                  _c("img", {
                                    staticClass:
                                      "on-right-sm relative-position",
                                    staticStyle: { top: "4px" },
                                    attrs: {
                                      helper: "",
                                      src: "/statics/_demo/edit-text.svg",
                                      width: "22"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            { staticClass: "text-weight-bold text-left" },
                            [_vm._v("Localization")]
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.changeCurrency($event)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-cash",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Currency")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Unit for pricing")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-item-tile", { attrs: { label: "" } }, [
                                    _vm._v(_vm._s(_vm.currency.label))
                                  ]),
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { sublabel: "" }
                                    },
                                    [_vm._v(_vm._s(_vm.currency.name))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-protect text-left"
                            },
                            [_vm._v("Danger Zone")]
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.removeAllServices($event)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-trash",
                                      color: "protect",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize text-protect",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Delete All Services")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Operation cannot be reversed")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { link: "", tag: "label" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.remove($event)
                                }
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-trash",
                                      color: "protect",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize text-protect",
                                      attrs: { label: "" }
                                    },
                                    [_vm._v("Delete Channel")]
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [_vm._v("Operation cannot be reversed")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list-header",
                        {
                          staticClass: "text-weight-bold text-grey text-right"
                        },
                        [
                          _vm._v(
                            "#" +
                              _vm._s(_vm.product.id) +
                              "-" +
                              _vm._s(_vm.product.mutations) +
                              " " +
                              _vm._s(
                                _vm._f("dt12format_EST")(_vm.product.updated)
                              ) +
                              " (EST)"
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogHOO", position: "bottom" },
              model: {
                value: _vm.dialogHOOShow,
                callback: function($$v) {
                  _vm.dialogHOOShow = $$v
                },
                expression: "dialogHOOShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [_c("q-toolbar-title", [_vm._v("Hours of Operations")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialogHOOShow = false
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v("Define your "),
                        _c("strong", { staticClass: "text-empower" }, [
                          _vm._v("regular")
                        ]),
                        _vm._v(" hours of operation.")
                      ]),
                      _c(
                        "q-tabs",
                        {
                          attrs: {
                            color: "gray",
                            inverted: "",
                            dark: _vm.anyDarkmode
                          }
                        },
                        [
                          _vm._l(_vm.daysOfTheWeek, function(day, ix) {
                            return _c(
                              "q-tab",
                              {
                                key: "tab-hoo-" + day + "-key",
                                attrs: {
                                  slot: "title",
                                  name: "tab-hoo-" + day,
                                  default: _vm.today_day === day,
                                  "data-tabix": "tab-" + ix
                                },
                                on: { select: _vm.HooSelectionChange },
                                slot: "title"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-size-120p text-system-brand text-weight-semibold",
                                    class: {
                                      "text-underline": _vm.today_day === day
                                    }
                                  },
                                  [_vm._v(_vm._s(day.substr(0, 2)))]
                                )
                              ]
                            )
                          }),
                          _vm._l(_vm.daysOfTheWeek, function(day, ix) {
                            return _c(
                              "q-tab-pane",
                              {
                                key: "tab-hoo-" + day + "-pane-key",
                                attrs: { name: "tab-hoo-" + day }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-center on-top-lg text-weight-semibold bg-shallower",
                                    staticStyle: {
                                      border: "1px solid #eee",
                                      margin: "10px",
                                      padding: "10px",
                                      "border-radius": "0.4em",
                                      width: "auto"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(day) +
                                        ":\n              "
                                    ),
                                    _vm.dialogHOOIndex
                                      ? [
                                          _vm.product.data.business.hoo[
                                            _vm.dialogHOOIndex
                                          ].isClosed
                                            ? _c("span", [_vm._v("Closed")])
                                            : _vm.product.data.business.hoo[
                                                _vm.dialogHOOIndex
                                              ].is24
                                            ? _c("span", [_vm._v("24 Hours")])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("t12format")(
                                                      _vm.product.data.business
                                                        .hoo[_vm.dialogHOOIndex]
                                                        .open
                                                    )
                                                  ) +
                                                    "-" +
                                                    _vm._s(
                                                      _vm._f("t12format")(
                                                        _vm.product.data
                                                          .business.hoo[
                                                          _vm.dialogHOOIndex
                                                        ].close
                                                      )
                                                    )
                                                )
                                              ])
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "q-list",
                        { attrs: { link: "", "no-border": "" } },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "no-margin-top text-weight-bold text-grey"
                            },
                            [
                              _vm._v("Duration\n            "),
                              _vm.dialogHOOIndex
                                ? [
                                    _vm.product.data.business.hoo[
                                      _vm.dialogHOOIndex
                                    ].isClosed
                                      ? _c("span", [_vm._v("(None)")])
                                      : _vm.product.data.business.hoo[
                                          _vm.dialogHOOIndex
                                        ].is24
                                      ? _c("span", [_vm._v("(24 hours)")])
                                      : _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                (
                                                  _vm.HooSelectionDuration(
                                                    _vm.product.data.business
                                                      .hoo[_vm.dialogHOOIndex]
                                                      .open,
                                                    _vm.product.data.business
                                                      .hoo[_vm.dialogHOOIndex]
                                                      .close,
                                                    "minutes"
                                                  ) / 60
                                                )
                                                  .toString()
                                                  .replace(".5", "½")
                                              ) +
                                              " hours)"
                                          )
                                        ])
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: {
                                tag: "label",
                                disabled:
                                  _vm.dialogHOO24h || _vm.dialogHOOClosed
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-sunny",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Opening\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-select", {
                                    attrs: {
                                      radio: "",
                                      separator: "",
                                      options: _vm.HOOOptionsOpen,
                                      disabled:
                                        _vm.dialogHOO24h || _vm.dialogHOOClosed,
                                      readonly:
                                        _vm.dialogHOO24h || _vm.dialogHOOClosed
                                    },
                                    on: { input: _vm.HooSelectionChangeOpen },
                                    model: {
                                      value: _vm.dialogHOOStart,
                                      callback: function($$v) {
                                        _vm.dialogHOOStart = $$v
                                      },
                                      expression: "dialogHOOStart"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: {
                                tag: "label",
                                disabled:
                                  _vm.dialogHOO24h || _vm.dialogHOOClosed
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-moon",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Closing\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-select", {
                                    attrs: {
                                      radio: "",
                                      separator: "",
                                      options: _vm.HOOOptionsClose,
                                      disabled:
                                        _vm.dialogHOO24h || _vm.dialogHOOClosed,
                                      readonly:
                                        _vm.dialogHOO24h || _vm.dialogHOOClosed
                                    },
                                    on: { input: _vm.HooSelectionChangeClose },
                                    model: {
                                      value: _vm.dialogHOOEnd,
                                      callback: function($$v) {
                                        _vm.dialogHOOEnd = $$v
                                      },
                                      expression: "dialogHOOEnd"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("q-item-separator"),
                          _c(
                            "q-item",
                            {
                              attrs: {
                                tag: "label",
                                disabled: _vm.dialogHOOClosed
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-infinite",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                24 Hours\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-toggle", {
                                    attrs: {
                                      dark: _vm.anyDarkmode,
                                      readonly: _vm.dialogHOOClosed,
                                      disabled: _vm.dialogHOOClosed
                                    },
                                    on: { input: _vm.HooSelectionChange24h },
                                    model: {
                                      value: _vm.dialogHOO24h,
                                      callback: function($$v) {
                                        _vm.dialogHOO24h = $$v
                                      },
                                      expression: "dialogHOO24h"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: {
                                tag: "label",
                                disabled: _vm.dialogHOO24h
                              }
                            },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c("q-icon", {
                                    attrs: {
                                      name: "ion-exit",
                                      color: _vm.anyDarkmode
                                        ? ""
                                        : "blue-grey-10",
                                      size: "33px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    {
                                      staticClass: "capitalize",
                                      attrs: { label: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Closed\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-toggle", {
                                    attrs: {
                                      dark: _vm.anyDarkmode,
                                      readonly: _vm.dialogHOO24h,
                                      disabled: _vm.dialogHOO24h
                                    },
                                    on: { input: _vm.HooSelectionChangeClosed },
                                    model: {
                                      value: _vm.dialogHOOClosed,
                                      callback: function($$v) {
                                        _vm.dialogHOOClosed = $$v
                                      },
                                      expression: "dialogHOOClosed"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogQRCode", position: "bottom" },
              model: {
                value: _vm.dialogQRCodeShow,
                callback: function($$v) {
                  _vm.dialogQRCodeShow = $$v
                },
                expression: "dialogQRCodeShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                {
                  staticStyle: {
                    "background-image": "url(/statics/_demo/qrcode_primary.svg)"
                  }
                },
                [
                  _c(
                    "q-toolbar",
                    {
                      directives: [
                        {
                          name: "touch-pan",
                          rawName: "v-touch-pan.vertical.prevent.stop",
                          value: _vm.modalAdapt,
                          expression: "modalAdapt",
                          modifiers: {
                            vertical: true,
                            prevent: true,
                            stop: true
                          }
                        }
                      ],
                      staticClass: "cursor-grab",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c("q-toolbar-title", [
                        _vm._v(_vm._s(_vm.$t("QRCODE.LABEL")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    loading: _vm.dialogQRCodeDownloading,
                                    disabled: !_vm.channel.qrcode_ref,
                                    color: "empower-light",
                                    "text-color": "empower",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.qrcodeDownload($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("DOWNLOAD")) +
                                      "\n              "
                                  ),
                                  _c("q-spinner-puff", {
                                    attrs: { slot: "loading", size: "20px" },
                                    slot: "loading"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialogQRCodeShow = false
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/statics/_demo/" +
                                        (_vm.anyDarkmode
                                          ? "chevron.compact.down_white.svg"
                                          : "chevron.compact.down_primary.svg"),
                                      height: "10"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "layout-padding no-padding-top text-center"
                    },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "transition",
                        {
                          attrs: {
                            appear: "",
                            "enter-active-class":
                              "animated fadeInUp animated-d800"
                          }
                        },
                        [
                          _vm.channel.qrcode_ref
                            ? _c("img", {
                                staticStyle: {
                                  width: "95%",
                                  "border-radius": "2rem",
                                  "max-width": "53vh"
                                },
                                attrs: { src: _vm.channel.qrcode_ref }
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "layout-padding",
                                  staticStyle: {
                                    "margin-top": "40%",
                                    padding: "0 30%"
                                  }
                                },
                                [
                                  _c("q-progress", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "secondary",
                                      stripe: "",
                                      animate: "",
                                      height: "12px"
                                    }
                                  })
                                ],
                                1
                              )
                        ]
                      ),
                      _c(
                        "transition",
                        {
                          attrs: {
                            appear: "",
                            "enter-active-class":
                              "animated fadeInUp animated-d800"
                          }
                        },
                        [
                          _vm.channel.qrcode_ref
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "text-family-brand text-weight-semibold text-center font-size-100p text-attention",
                                  staticStyle: {
                                    "word-break": "break-all",
                                    "margin-bottom": "-20px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.product.data.shortlink.replace(
                                          "ltsbtrf.ly",
                                          "mywin.gs"
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                  _c("q-btn", {
                                    attrs: {
                                      flat: "",
                                      round: "",
                                      icon: "ion-link",
                                      title: _vm.product.data.shortlink.replace(
                                        "ltsbtrf.ly",
                                        "mywin.gs"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.openURL(
                                          _vm.product.data.shortlink.replace(
                                            "ltsbtrf.ly",
                                            "mywin.gs"
                                          )
                                        )
                                      }
                                    }
                                  }),
                                  _c("q-btn", {
                                    attrs: {
                                      flat: "",
                                      round: "",
                                      icon: "ion-refresh"
                                    },
                                    on: { click: _vm.qrcodeCreate }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              attrs: {
                minimized: "",
                "no-esc-dismiss": "",
                "no-backdrop-dismiss": ""
              },
              model: {
                value: _vm.dialogRemovingShow,
                callback: function($$v) {
                  _vm.dialogRemovingShow = $$v
                },
                expression: "dialogRemovingShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    { attrs: { slot: "header", inverted: "" }, slot: "header" },
                    [
                      _c("q-toolbar-title", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.getEcosystemLabel("DELETE.DELETING"))
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding" },
                    [
                      _c("q-progress", {
                        attrs: {
                          indeterminate: "",
                          color: "secondary",
                          stripe: "",
                          animate: "",
                          height: "12px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "flex flex-center", staticStyle: { height: "100vh" } },
        [
          _c("q-spinner-puff", {
            staticClass: "loading-spinner loading-spinner-gold",
            staticStyle: { color: "#F4A724", display: "block" },
            attrs: { size: "200px" }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }